<ion-content>
  <div class="background-image">
    <ion-img src="{{randomImage}}"></ion-img>
    <div class="gradient"></div>
    <div class="gradient2"></div>
  </div>

  <ion-fab vertical="top" horizontal="end" slot="fixed">
    <ion-fab-button color="white" size="small" (click)="dismiss()">
      <ion-icon name="close"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <div class="container">
    <div class="middle">
      <div class="allWidth first-explication flex-col-center">
        <ion-img
          class="img-giorgio float"
          src="assets/images/characters/specific/first.png"
        ></ion-img>
      </div>
      <h2>{{'affiliation.title' | translate }}</h2>
      <div class="allWidth flex-col-vertical-center">
        <ion-item
          button
          lines="none"
          detail
          detailIcon="copy"
          (click)="copyLink()"
        >
          <ion-icon name="link" slot="start"></ion-icon>
          <ion-label>{{url}}</ion-label>
        </ion-item>
        <div class="flex-col-center ion-double-margin-top">
          <ion-button
            color="primary-gradient"
            shape="round"
            (click)="shareOn('whatsapp')"
            size="large"
          >
            <ion-icon name="logo-whatsapp" slot="icon-only"></ion-icon>
          </ion-button>

          <ion-button
            color="primary-gradient"
            shape="round"
            (click)="shareOn('facebook')"
            size="large"
            class="ion-margin-start ion-margin-end"
          >
            <ion-icon name="logo-facebook" slot="icon-only"></ion-icon>
          </ion-button>

          <ion-button
            color="primary-gradient"
            shape="round"
            (click)="shareOn('mail')"
            size="large"
          >
            <ion-icon name="mail" slot="icon-only"></ion-icon>
          </ion-button>
        </div>

        <p class="ion-margin-top affiliate-pro hover" (click)="openPro()">
          {{'affiliation.pro' | translate}}
        </p>
      </div>
    </div>
  </div>
</ion-content>
