import { Component, OnInit, HostListener, Input } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ServicesService } from '@app/services/services.service';
import { RegisterPage } from '../register/register.page';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
  @Input() set_email: string = null;
  validations_form_email: FormGroup;
  validations_form_code: FormGroup;
  stepLogin: number = 1;
  randomImage: string = '';

  constructor(
    public services: ServicesService,
    private formBuilder: FormBuilder
  ) {
    this.randomImage = this.services.utilsService.randomImageCategories();

    this.validations_form_email = this.formBuilder.group({
      email: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(255),
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ])
      ),
    });
    this.validations_form_code = this.formBuilder.group({
      code: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(50),
          Validators.minLength(6),
        ])
      ),
    });
  }

  ngOnInit() {
    if (this.set_email) {
      this._askValidationCode(this.set_email);
    }
  }

  email: string = null;

  askValidationCode() {
    if (!this.validations_form_email.valid) {
      this.validations_form_email.markAllAsTouched();
      return;
    }

    this._askValidationCode(
      this.validations_form_email.controls['email'].value
    );
  }

  _askValidationCode(email) {
    this.email = email;

    this.email = this.email.toLowerCase();
    this.email = this.email.replace(' ', '');
    this.services.utilsService.presentLoading();

    this.services.apiService
      .get(
        'auth/verificationcode?ngsw-bypass=true&email=' +
          encodeURIComponent(this.email)
      )
      .subscribe({
        next: (data: any) => {
          if (data.status_code == 200) {
            this.stepLogin = 2;
          } else {
            this.services.utilsService.presentToast(
              'login.noAccount',
              'alert-circle',
              'warning-toast'
            );
          }
        },
        error: (e) => {
          console.error(e);
        },
        complete: () => {},
      })
      .add(() => {
        this.services.utilsService.dismissLoading();
      });
  }

  loginSocial(social: string) {
    this.services.sessionService.loginSocial(social).then(() => {
      this.services.utilsService.presentToast('login.connected', 'checkmark');

      setTimeout(() => {
        this.dismiss();
      }, 200);
    });
  }

  validateCodeOrPassword() {
    if (!this.validations_form_code.valid) {
      this.validations_form_code.markAllAsTouched();
      return;
    }

    let code = this.validations_form_code.controls['code'].value;

    let data = JSON.stringify({
      email: this.email,
      code: code,
    });

    this.services.utilsService.presentLoading();

    this.services.apiService
      .post('auth/login', data)
      .subscribe({
        next: (data: any) => {
          if (data.status_code == 200) {
            this.services.sessionService.setToken(data.access_token);

            this.services.utilsService.presentToast(
              'login.connected',
              'checkmark'
            );

            this.dismiss();
          } else if (data.status_code == 404) {
            this.services.utilsService.presentToast(
              'login.noAccount',
              'alert-circle',
              'warning-toast'
            );
          } else {
            this.services.utilsService.presentToast(
              'login.wrongCredentials',
              'alert-circle',
              'warning-toast'
            );
          }
        },
        error: (e) => {
          console.error(e);
        },
        complete: () => {},
      })
      .add(() => {
        this.services.utilsService.dismissLoading();
      });
  }

  async register() {
    this.dismiss();
    setTimeout(async () => {
      const modal = await this.services.modalController.create({
        component: RegisterPage,
        componentProps: {},
      });
      modal.present();
    }, 200);
  }

  @HostListener('document:keypress', ['$event'])
  enterKey(event) {
    if (event.key == 'Enter') {
      if (this.stepLogin == 1) {
        this.askValidationCode();
      } else this.stepLogin == 2;
      {
        this.validateCodeOrPassword();
      }
    }
  }
  ionViewWillEnter() {
    const modalState = {
      modal: true,
      desc: 'fake state for our modal',
    };
    history.pushState(modalState, null!);
  }

  ionViewWillLeave() {
    if (window.history.state.modal) {
      window.history.back();
    }
  }

  @HostListener('window:popstate', ['$event'])
  async dismiss(role: string = 'dismiss') {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    const modal = await this.services.modalController.getTop();
    if (modal) {
      this.services.modalController.dismiss(null, role);
      return;
    }
  }
}
