import { Component, OnInit, HostListener, Input } from '@angular/core';
import { ServicesService } from '@app/services/services.service';

@Component({
  selector: 'app-affiliate',
  templateUrl: './affiliate.page.html',
  styleUrls: ['./affiliate.page.scss'],
})
export class AffiliatePage implements OnInit {
  randomImage: string = '';
  url: string = '';

  constructor(public services: ServicesService) {
    this.randomImage = this.services.utilsService.randomImagePsychology();
  }

  ngOnInit() {
    if (this.services.sessionService.user != null) {
      this.url =
        'https://web.loveflame.app?ref=u_' +
        this.services.sessionService.user.id;
    } else {
      this.url = 'https://web.loveflame.app?ref=u_1024';
    }
  }

  ionViewWillEnter() {
    const modalState = {
      modal: true,
      desc: 'fake state for our modal',
    };
    history.pushState(modalState, null!);
  }

  ionViewWillLeave() {
    if (window.history.state.modal) {
      window.history.back();
    }
  }

  copyLink() {
    navigator.clipboard.writeText(this.url);
    this.services.utilsService.presentToast('affiliation.copyLink');
  }

  openPro() {
    this.services.browserService.openUrl(
      this.services.utilsService.getWebsiteUrl('affiliate-program-love-flame'),
      true
    );
  }

  shareOn(name) {
    let url = encodeURIComponent(this.url);
    let title = this.services.translateService.instant('affiliation.joinText');
    let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
    width=0,height=0,left=-1000,top=-1000`;
    switch (name) {
      case 'whatsapp':
        this.services.browserService.openWithParams(
          `https://api.whatsapp.com/send?text=${url}`,
          'whatsapp',
          params
        );
        break;
      case 'facebook':
        this.services.browserService.openWithParams(
          `https://www.facebook.com/sharer.php?u=${url}`,
          'facebook',
          params
        );
        break;
      case 'email':
        this.services.browserService.openUrl(
          `mailto:test@example.com?subject=${title}:${url}`
        );

        break;
    }
  }

  @HostListener('window:popstate', ['$event'])
  async dismiss(role: string = 'dismiss') {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    const modal = await this.services.modalController.getTop();
    if (modal) {
      this.services.modalController.dismiss(null, role);
      return;
    }
  }
}
