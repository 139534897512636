import { Component, OnInit, HostListener, Input } from '@angular/core';
import { ServicesService } from '@app/services/services.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
@Component({
  selector: 'app-fast-register',
  templateUrl: './fast-register.page.html',
  styleUrls: ['./fast-register.page.scss'],
})
export class FastRegisterPage implements OnInit {
  randomImage: string = '';
  validations_form_email: FormGroup;
  trial_time: number = 7;

  constructor(
    public services: ServicesService,
    private formBuilder: FormBuilder
  ) {
    this.validations_form_email = this.formBuilder.group({
      email: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(255),
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ])
      ),
    });

    if (this.services.sessionService.guest_user) {
      this.trial_time = this.services.sessionService.guest_user['trial-time'];
    } else {
      /*this.dismiss();
      setTimeout(() => {
        this.services.modalService.openPremium();
      }, 200);*/
    }

    this.randomImage = this.services.utilsService.randomImagePsychology();
  }

  ngOnInit() {}

  loginSocial(social: string) {
    this.services.sessionService.loginSocial(social).then(() => {
      this.services.utilsService.presentToast(
        'fast-register.trialActivated',
        'checkmark'
      );
      setTimeout(() => {
        this.dismiss();
      }, 200);
    });
  }

  register() {
    if (!this.validations_form_email.valid) {
      this.validations_form_email.markAllAsTouched();
      return;
    }
    let email = this.validations_form_email.controls['email'].value;

    email = email.toLowerCase();
    email = email.replace(' ', '');
    this.services.utilsService.presentLoading();

    let data = JSON.stringify({
      unique_identifier: this.services.sessionService.unique_identifier,
      email: email,
      language: this.services.sessionService.language,
      timezone: this.services.utilsService.getTimezone(),
    });

    this.services.apiService
      .post('auth/register', data)
      .subscribe({
        next: (data: any) => {
          if (data.status_code == 200) {
            //this.services.utilsService.presentToast('register.successful');

            this.services.sessionService.setToken(data.access_token);

            this.services.utilsService.presentToast(
              'fast-register.trialActivated',
              'checkmark'
            );

            this.dismiss();
          } else if (data.status_code == 402) {
            this.services.utilsService.presentToast(
              'login.problemEmail',
              'alert-circle',
              'warning-toast'
            );
          } else {
            this.services.utilsService.presentToast(
              'register.emailExist',
              'alert-circle',
              'warning-toast'
            );
            this.dismiss();
            setTimeout(async () => {
              this.services.modalService.openLogin(email);
            }, 200);
          }
        },
        error: (e) => {
          console.error(e);
        },
        complete: () => {},
      })
      .add(() => {
        this.services.utilsService.dismissLoading();
      });
  }

  ionViewWillEnter() {
    const modalState = {
      modal: true,
      desc: 'fake state for our modal',
    };
    history.pushState(modalState, null!);
  }

  ionViewWillLeave() {
    if (window.history.state.modal) {
      window.history.back();
    }
  }

  @HostListener('window:popstate', ['$event'])
  async dismiss(role: string = 'dismiss') {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    const modal = await this.services.modalController.getTop();
    if (modal) {
      this.services.modalController.dismiss(null, role);
      return;
    }
  }
}
