import { Component, OnInit, HostListener, Input } from '@angular/core';
import { ServicesService } from '@app/services/services.service';

@Component({
  selector: 'app-making-of',
  templateUrl: './making-of.page.html',
  styleUrls: ['./making-of.page.scss'],
})
export class MakingOfPage implements OnInit {
  randomImage: string = '';

  constructor(public services: ServicesService) {
    this.randomImage = this.services.utilsService.randomImagePsychology();
  }

  ngOnInit() {}

  ionViewWillEnter() {
    const modalState = {
      modal: true,
      desc: 'fake state for our modal',
    };
    history.pushState(modalState, null!);
  }

  ionViewWillLeave() {
    if (window.history.state.modal) {
      window.history.back();
    }
  }

  @HostListener('window:popstate', ['$event'])
  async dismiss(role: string = 'dismiss') {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    const modal = await this.services.modalController.getTop();
    if (modal) {
      this.services.modalController.dismiss(null, role);
      return;
    }
  }
}
