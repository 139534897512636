import { Component, OnInit, HostListener, Input } from '@angular/core';
import { Debug } from '@app/class/debug';
import { ServicesService } from '@app/services/services.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.page.html',
  styleUrls: ['./faq.page.scss'],
})
export class FaqPage implements OnInit {
  list_faq: any;
  randomImage: string = '';

  constructor(public services: ServicesService) {
    this.randomImage = this.services.utilsService.randomImageCategories();
  }

  ngOnInit() {
    /*
     {
            title: 'Ecrire à la psychologue IA',
            text: 'Pour écrire à notre psychologue IA, vous retrouvez une conversation spécifique dans votre messagerie privée. Il vous suffit de lui écrire directement dans cette conversation.<br><br>Vous pouvez aussi cliquer sur le bouton ci-dessous pour lui écrire un message directement, elle vous répondra sous peu !',
            buttons: [
              {
                title: 'Ecrire à la psychologue IA',
                name: 'chatbubble-ellipses',
                function: () => {},
              },
            ],
          },*/
    this.list_faq = [
      {
        title: 'faq.general',
        items: [
          {
            title: 'faq.howLoveFlameFunction.title',
            text: 'faq.howLoveFlameFunction.text',
          },
          {
            title: 'faq.whySexIsImportant.title',
            text: 'faq.whySexIsImportant.text',
          },
          {
            title: 'faq.canIUseLoveFlameAlone.title',
            text: 'faq.canIUseLoveFlameAlone.text',
          },
          {
            title: 'faq.canIUseLoveFlameWithMultiplePartners.title',
            text: 'faq.canIUseLoveFlameWithMultiplePartners.text',
          },
          {
            title: 'faq.canIUseLoveFlameWithEverybody.title',
            text: 'faq.canIUseLoveFlameWithEverybody.text',
          },
          {
            title: 'faq.isItProved.title',
            text: 'faq.isItProved.text',
          },
          {
            title: 'faq.isItApprobedBySexotherapist.title',
            text: 'faq.isItApprobedBySexotherapist.text',
          },
          {
            title: 'faq.whyDoYouHaveCreated.title',
            text: 'faq.whyDoYouHaveCreated.text',
          },
        ],
      },
      {
        title: 'faq.functionnalities',
        items: [
          {
            title: 'faq.howUseTheSuivi.title',
            text: 'faq.howUseTheSuivi.text',
          },
          {
            title: 'faq.howUseTheEvolution.title',
            text: 'faq.howUseTheEvolution.text',
          },
          {
            title: 'faq.howToUseMissions.title',
            text: 'faq.howToUseMissions.text',
          },
          {
            title: 'faq.howToUsePsychology.title',
            text: 'faq.howToUsePsychology.text',
          },
        ],
      },
    ];

    if (!this.services.sessionService.ios_free) {
      this.list_faq.push({
        title: 'faq.pricing',
        items: [
          {
            title: 'faq.freeTest.title',
            text: 'faq.freeTest.text',
          },
          {
            title: 'faq.price.title',
            text: 'faq.price.text',
            buttons: [
              {
                title: 'premium.seeOurOffer',
                name: 'chevron-forward',
                function: () => {
                  this.services.modalService.openPremium();
                },
              },
            ],
          },
          {
            title: 'faq.lifetime_offer.title',
            text: 'faq.lifetime_offer.text',
          },
          {
            title: 'faq.refund.title',
            text: 'faq.refund.text',
          },
        ],
      });
    }
  }

  ionViewWillEnter() {
    const modalState = {
      modal: true,
      desc: 'fake state for our modal',
    };
    history.pushState(modalState, null!);
  }

  ionViewWillLeave() {
    if (window.history.state.modal) {
      window.history.back();
    }
  }

  openButton(button) {
    if (button.function) {
      //this.dismiss();
      setTimeout(() => {
        button.function();
      }, 100);
    }
  }

  openItem(item) {
    let actual = item.opened;
    for (let cat of this.list_faq) {
      for (let item of cat.items) {
        //item.opened = false;
      }
    }
    item.opened = !actual;
  }

  contact() {
    this.services.modalService.openContact();
  }

  @HostListener('window:popstate', ['$event'])
  async dismiss(role: string = 'dismiss') {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    const modal = await this.services.modalController.getTop();
    if (modal) {
      this.services.modalController.dismiss(null, role);
      return;
    }
  }
}
