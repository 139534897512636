import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from './storage.service';
import { first } from 'rxjs/operators';
import { ServicesService } from './services.service';
import { DateTime } from 'luxon';
import { Debug } from '@app/class/debug';
@Injectable({
  providedIn: 'root',
})
export class SessionService {
  public language: string = 'fr';
  public lifetime: number = 0;
  public ready: boolean = false;
  public unique_identifier: string = '';
  public ios_free: boolean = false;
  public currency: string = 'usd';
  constructor(
    private translateService: TranslateService,
    private storageService: StorageService,
    private services: ServicesService
  ) {
    this.free_trial = {
      date_finish: DateTime.now()
        .plus({
          day: 1,
        })
        .toISO(),
      date_staying: 1,
    };
    this.is_premium = true;
  }

  start() {
    this.unique_identifier_generation();
    console.log('ID:' + this.unique_identifier);
    this.init();
    this.ready = true;
  }

  unique_identifier_generation() {
    if (this.storageService.check('unique_identifier')) {
      this.unique_identifier = this.storageService.get('unique_identifier');
    } else {
      this.unique_identifier = this.services.utilsService.generateUUID();
      this.storageService.set('unique_identifier', this.unique_identifier);
    }

    return this.unique_identifier;
  }

  init() {
    if (
      (Debug.ios_publication_mode && this.services.utilsService.isNativeIOS) ||
      Debug.disable_premium
    ) {
      this.ios_free = true;
      this.is_premium = true;
    }

    let api_token = this.storageService.get('api_token', null);

    if (this.storageService.check('temp_user')) {
      this.checkFreeTrial(this.storageService.get('temp_user'));
    }

    if (api_token != null) {
      this.user = this.storageService.get('user', null);

      if (this.user != null) {
        this.checkBuy();
      }

      this.services.apiService.setToken(api_token);
      this.getUser();
    } else {
      this.logGuest();
    }
  }

  logGuest() {
    let utm_source = null;
    let ref = null;
    if (this.services.menuService.getParams['utm_source'] != null) {
      utm_source = this.services.menuService.getParams['utm_source'];
    }

    if (this.services.menuService.getParams['ref'] != null) {
      ref = this.services.menuService.getParams['ref'];
    }

    if (utm_source == null && this.services.utilsService.isNativeAndroid) {
      utm_source = 'AndroidApp';
    }
    if (utm_source == null && this.services.utilsService.isNativeIOS) {
      utm_source = 'IOSApp';
    }

    if (utm_source == null) {
      utm_source = document.referrer;
    }

    let data = JSON.stringify({
      timezone: this.services.utilsService.getTimezone(),
      language: this.language,
      unique_identifier: this.services.sessionService.unique_identifier,
      utm_source: utm_source,
      ref: ref,
      //recaptchaToken: this.recaptchaToken
    });

    this.services.apiService
      .post('auth/guest?ngsw-bypass=true', data)
      .subscribe({
        next: (data: any) => {
          if (data.status_code == 200) {
            this.storageService.set('temp_user', data.user);
            this.checkFreeTrial(data.user);
            this.guest_user = data.user;

            let is_new = this.storageService.get('new', true);
            if (!this.checkIfDataFilled()) {
              this.services.menuService.navigate('welcome');
            }

            if (Debug.generate_new_data) {
              this.services.dataService.generateTestData();
            }
            this.number_of_connection = data.user.number_of_connection;

            this.alert();

            this.currency = data.user.currency;

            this.storageService.set('new', false);
          } else {
          }
        },
        error: (e) => {
          console.error(e);
        },
        complete: () => {},
      });
  }

  public checkIfDataFilled() {
    if (this.services.dataService.data == null) {
      return false;
    }

    if (this.services.dataService.data['love_attitudes'] != null) {
      return true;
    }

    if (this.services.dataService.data['role_repartition'] != null) {
      return true;
    }

    if (this.services.dataService.data['love_languages'] != null) {
      return true;
    }
    if (this.services.dataService.data['mini_test_lovelanguage'] != null) {
      return true;
    }

    if (
      this.services.dataService.data['suivi'] != null &&
      this.services.dataService.data['suivi'].length > 0
    ) {
      return true;
    }

    return false;
  }

  alert_showed: boolean = false;
  alert() {
    // ALERT 2 SECONDS FAST REGISTER FOR GUEST WHEN TRIAL IS FINISH
    if (
      !this.alert_showed &&
      this.services.sessionService.user == null &&
      !this.is_premium &&
      this.checkIfDataFilled()
    ) {
      this.alert_showed = true;

      setTimeout(() => {
        this.services.modalService.openFastRegister();
      }, 2000);
    }

    // ALERTE 5 SECONDS EACH 10 CONNECTIONS FOR REVIEW
    if (
      !this.alert_showed &&
      this.number_of_connection % 10 == 0 &&
      !this.services.storageService.check('review_give') &&
      this.checkIfDataFilled()
    ) {
      this.alert_showed = true;

      setTimeout(() => {
        this.services.modalService.openReview();
      }, 5000);
    }

    // ALERTE 5 SECONDS EACH 5 CONNECTIONS FOR AFFILIATION
    if (
      !this.alert_showed &&
      this.number_of_connection % 5 == 0 &&
      this.user != null &&
      this.has_buyed != null
    ) {
      this.alert_showed = true;

      setTimeout(() => {
        this.services.modalService.openAffiliate();
      }, 5000);
    }

    // ALERTE 5 SECONDS EACH 3 CONNECTIONS INSTALLATION
    if (
      !this.alert_showed &&
      this.number_of_connection % 3 == 0 &&
      ((this.services.utilsService.isAndroid &&
        !this.services.utilsService.isNativeAndroid) ||
        (this.services.utilsService.isIOS &&
          !this.services.utilsService.isNativeIOS))
    ) {
      this.alert_showed = true;

      setTimeout(() => {
        this.services.modalService.openInstall();
      }, 5000);
    }

    // ALERTE 60 SECONDES INSTALLATION
    if (
      !this.alert_showed &&
      this.user == null &&
      ((this.services.utilsService.isAndroid &&
        !this.services.utilsService.isNativeAndroid) ||
        (this.services.utilsService.isIOS &&
          !this.services.utilsService.isNativeIOS))
    ) {
      this.alert_showed = true;
      setTimeout(() => {
        if (!this.services.menuService.currentPage.includes('welcome'))
          this.services.modalService.openInstall();
      }, 60000);
    }

    // ALERTE 60 SECONDES GUEST USER FAST REGISTER
    if (!this.alert_showed && this.services.sessionService.user == null) {
      this.alert_showed = true;

      setTimeout(() => {
        if (!this.services.menuService.currentPage.includes('welcome'))
          this.services.modalService.openFastRegister();
      }, 90000);
    }
    if (Debug.popup_test) {
      setTimeout(() => {
        this.services.modalService.openAffiliate();
        this.services.modalService.openReview();
        this.services.modalService.openNotificationAsk();
        this.services.modalService.openPremium();
        this.services.modalService.openInstall();
        this.services.modalService.openFastRegister();
      }, 1000);
    }
  }

  public user: any = null;
  public is_premium: boolean = false;

  public has_buyed: boolean = false;
  free_trial_day: number = 7;
  public free_trial: any = null;

  public guest_user: any = null;
  public number_of_connection: number = 0;
  getUser() {
    this.services.apiService.get('auth/user?ngsw-bypass=true').subscribe({
      next: (data: any) => {
        if (data.status_code == 200) {
          this.user = data.user;
          this.storageService.set('user', this.user);
          this.storageService.set('temp_user', data.user);

          this.checkFreeTrial(this.user);
          this.checkBuy();

          console.log('USER');
          console.log(this.user);
          this.currency = this.user.currency;
          this.unique_identifier = data.user.unique_identifier;
          this.storageService.set('unique_identifier', this.unique_identifier);

          this.number_of_connection = this.user.number_of_connection;
          this.setLanguage(this.user.language, false);
          this.alert();

          if (this.user.saves.length > 0) {
            let save = this.user.saves[0];
            if (
              this.services.dataService.all_datas == null ||
              this.services.dataService.all_datas.count == null ||
              this.services.dataService.all_datas.count < save.count
            ) {
              this.services.dataService.loadData(save.save);
              this.services.dataService.saveData(true);
              this.services.eventService.publish('refresh_graphs');
            }
          }
        } else {
        }
      },
      error: (e) => {
        console.error(e);
      },
      complete: () => {},
    });
  }

  updateLanguage() {
    let data = JSON.stringify({
      language: this.language,
      unique_identifier: this.unique_identifier,
    });

    this.services.apiService.put('user/language', data).subscribe({
      next: (data: any) => {
        if (data.status_code == 200) {
          console.log('Language UPDATED');
        } else {
        }
      },
      error: (e) => {
        console.error(e);
      },
      complete: () => {},
    });
  }

  interval_social: any;
  loginSocial(social) {
    return new Promise((resolve, reject) => {
      this.services.dataLayerService.logEvent(
        'prospect',
        'signup',
        'signup',
        'signup'
      );

      let url = location.origin + location.pathname;
      if (url.includes('login')) {
        url = location.origin;
      }

      try {
        this.services.apiService
          .get(
            'auth/social/' +
              social +
              '?url=' +
              url +
              '&unique_identifier=' +
              this.unique_identifier
          )
          .subscribe((response: any) => {
            console.log(response);
            if (response.status_code == 200) {
              this.services.browserService.openUrl(response.url);
              let count = 0;
              clearInterval(this.interval_social);
              this.interval_social = setInterval(() => {
                count++;
                if (count > 120) {
                  clearInterval(this.interval_social);
                  reject(false);
                }

                this.services.apiService
                  .get(
                    'auth/social/token?unique_identifier=' +
                      this.unique_identifier
                  )
                  .subscribe((response: any) => {
                    console.log(response);

                    if (
                      response.status_code == 200 &&
                      response.token != null &&
                      this.interval_social != null
                    ) {
                      this.setToken(response.token);
                      clearInterval(this.interval_social);
                      this.interval_social = null;
                      resolve(true);

                      this.services.browserService.close();
                    }
                  });
              }, 1000);
            } else {
              reject(false);
            }
          });
      } catch (e) {
        reject(false);
      }
    });
  }

  updateUser() {
    if (this.user == null) {
      return;
    }
    let data = JSON.stringify({
      cloud_save: this.user.cloud_save,
      language: this.user.language,
    });

    this.services.apiService.put('user/' + this.user.id, data).subscribe({
      next: (data: any) => {
        if (data.status_code == 200) {
          console.log('USER UPDATED');
        } else {
        }
      },
      error: (e) => {
        console.error(e);
      },
      complete: () => {},
    });
  }

  checkBuy() {
    if (this.user.buyed.subscription != null || this.user.buyed.lifetime) {
      this.is_premium = true;
      this.has_buyed = true;
    }
  }

  public created_at: string = null;
  checkFreeTrial(user: any) {
    this.storageService.set('user_created_at', user.created_at);

    this.created_at = user.created_at;
    let date_creation = DateTime.fromISO(user.created_at);

    this.lifetime = Math.abs(
      this.services.dateService.getDayDifferenceFromToday(date_creation)
    );

    let trial_staying = 1;
    if (user.trial != null) {
      trial_staying = user.trial;
    }

    if (!this.storageService.check('day_trial')) {
      this.storageService.set('day_trial', trial_staying);
    }

    if (
      trial_staying > 0 &&
      !this.services.utilsService.getDebug('disable_trial')
    ) {
      this.free_trial = {
        date_finish: date_creation
          .plus({
            day: trial_staying,
          })
          .toISO(),
        date_staying: trial_staying,
      };

      this.is_premium = true;
    } else {
      this.free_trial = null;
      if (!this.ios_free) this.is_premium = false;
    }

    if (Debug.locked) {
      this.free_trial = null;
      this.is_premium = false;
      return;
    }
  }

  setLanguage(lang: string, update: boolean = true) {
    this.translateService.setDefaultLang(lang);
    this.translateService.use(lang);
    this.language = lang;

    this.storageService.set('language', lang);

    if (this.user != null && this.user.language != this.language) {
      this.user.language = this.language;
    }
    if (update) {
      if (this.user == null) {
        this.updateLanguage();
      } else {
        this.updateUser();
      }
    }
  }

  setToken(token) {
    this.services.storageService.set('api_token', token);
    this.services.apiService.setToken(token);
    this.getUser();
  }

  disconnect() {
    this.user = null;
    this.is_premium = false;
    this.has_buyed = false;
    this.services.storageService.delete('api_token');
    this.services.storageService.delete('created_at');
    this.services.storageService.delete('unique_identifier');
    this.services.storageService.delete('user');
    this.services.sessionService.logGuest();
  }

  isReady(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.ready) {
        resolve(true);
      } else {
        let interval = setInterval(() => {
          if (this.ready) {
            clearInterval(interval);
            resolve(true);
          }
        }, 200);
      }
    });
  }
}
