import { Component, OnInit, HostListener, Input } from '@angular/core';
import { ServicesService } from '@app/services/services.service';

@Component({
  selector: 'app-review',
  templateUrl: './review.page.html',
  styleUrls: ['./review.page.scss'],
})
export class ReviewPage implements OnInit {
  randomImage: string = '';
  state = 'review';

  constructor(public services: ServicesService) {
    this.randomImage = this.services.utilsService.randomImageCategories();
  }

  ngOnInit() {}

  redirect() {
    this.services.storageService.set('review_give', true);

    let url = '';
    if (this.services.utilsService.isAndroid) {
      url =
        'https://play.google.com/store/apps/details?id=app.loveflame.web.twa&hl=' +
        this.services.sessionService.language;
    } else if (this.services.utilsService.isIOS) {
      url =
        'https://apps.apple.com/us/app/loveflame-boost-your-intimacy/id6670175124';

      if (this.services.sessionService.language == 'fr') {
        url =
          'https://apps.apple.com/fr/app/loveflame-boost-your-intimacy/id6670175124';
      }

      if (this.services.sessionService.language == 'de') {
        url =
          'https://apps.apple.com/de/app/loveflame-boost-your-intimacy/id6670175124';
      }
    } else {
      url = 'https://trustpilot.com/review/loveflame.app';
    }

    this.services.browserService.openUrl(url);

    this.state = 'gift';
  }

  makingOf() {
    this.dismiss();
    setTimeout(() => {
      this.services.modalService.openMakingOf();
    }, 200);
  }

  ionViewWillEnter() {
    const modalState = {
      modal: true,
      desc: 'fake state for our modal',
    };
    history.pushState(modalState, null!);
  }

  ionViewWillLeave() {
    if (window.history.state.modal) {
      window.history.back();
    }
  }

  @HostListener('window:popstate', ['$event'])
  async dismiss(role: string = 'dismiss') {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    const modal = await this.services.modalController.getTop();
    if (modal) {
      this.services.modalController.dismiss(null, role);
      return;
    }
  }
}
