<ion-content>
  <div class="background-image">
    <ion-img src="{{randomImage}}"></ion-img>
    <div class="gradient"></div>
    <div class="gradient2"></div>
  </div>

  <ion-fab vertical="top" horizontal="end" slot="fixed">
    <ion-fab-button color="white" size="small" (click)="dismiss()">
      <ion-icon name="close"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <div class="container">
    <div class="middle allWidth flex-col-vertical-center">
      <ng-container *ngIf="state == 'question'">
        <h1>{{'pay-question.title' | translate}}</h1>
        <app-divider></app-divider>
        <div class="container-button flex-col-vertical-center">
          <ion-button
            color="primary-gradient"
            shape="round"
            (click)="clickButton('think')"
            >💭 {{'pay-question.iThink' | translate}}
            <ion-icon name="checkmark-outline" slot="end"></ion-icon>
          </ion-button>

          <ion-button
            color="primary-gradient"
            shape="round"
            (click)="clickButton('complex')"
            >😓 {{'pay-question.tooComplex' | translate}}
            <ion-icon name="checkmark-outline" slot="end"></ion-icon>
          </ion-button>

          <ion-button
            color="primary-gradient"
            shape="round"
            (click)="clickButton('expensive')"
            >💲 {{'pay-question.tooExpensive' | translate}}
            <ion-icon name="checkmark-outline" slot="end"></ion-icon>
          </ion-button>

          <ion-button
            color="primary-gradient"
            shape="round"
            (click)="clickButton('useful')"
            >❌ {{'pay-question.notUseful' | translate}}
            <ion-icon name="checkmark-outline" slot="end"></ion-icon>
          </ion-button>

          <ion-button
            color="primary-gradient"
            shape="round"
            (click)="clickButton('other')"
            >❓ {{'pay-question.other' | translate}}
            <ion-icon name="checkmark-outline" slot="end"></ion-icon>
          </ion-button>
        </div>
      </ng-container>

      <ng-container *ngIf="state == 'text'">
        <h1>{{title | translate}}</h1>
        <app-divider></app-divider>
        <form
          [formGroup]="validations_form_text"
          class="allWidth ion-margin-top"
        >
          <div
            *ngIf="validations_form_text.controls['text'].touched && !validations_form_text.controls['text'].valid"
            class="allWidth flex-col-center ion-margin-bottom"
          >
            <ion-icon class="ion-margin-end" name="warning-outline"></ion-icon>
            <span>{{'pay-question.problemText' | translate}}</span>
          </div>
          <div class="text-editor">
            <textarea
              #textArea
              formControlName="text"
              (ngModelChange)="ontextChange($event)"
              placeholder="{{ 'pay-question.placeHolder' | translate }}"
              #textarea
              [rows]="number_of_rows"
            ></textarea>
          </div>

          <div
            class="allWidth ion-text-center ion-margin-top ion-margin-bottom ion-padding-start ion-padding-end"
          >
            <ion-button
              color="primary-gradient"
              shape="round"
              size="large"
              (click)="emailText()"
            >
              <ion-icon name="chevron-forward" slot="end"></ion-icon>
              {{'login.valid'|translate}}
            </ion-button>
          </div>
        </form>
      </ng-container>

      <ng-container *ngIf="state == 'email'">
        <h1>{{'pay-question.emailTitle' | translate}}</h1>
        <app-divider></app-divider>

        <form [formGroup]="validations_form_email" class="allWidth">
          <div
            *ngIf="validations_form_email.controls['email'].touched && !validations_form_email.controls['email'].valid"
            class="allWidth flex-col-center"
          >
            <ion-icon class="ion-margin-end" name="warning-outline"></ion-icon>
            <span>{{'login.problemEmail' | translate}}</span>
          </div>
          <ion-item lines="none" class="transparent" class="ion-margin-top">
            <ion-icon color="primary" slot="start" name="mail"></ion-icon>
            <ion-input
              required
              formControlName="email"
              inputmode="email"
              type="email"
              placeholder="{{'login.yourEmail' | translate}}"
              autocomplete="on"
              required
            ></ion-input>
          </ion-item>
          <div
            class="allWidth ion-text-center ion-margin-top ion-margin-bottom ion-padding-start ion-padding-end"
          >
            <ion-button
              color="primary-gradient"
              shape="round"
              size="large"
              (click)="emailDiscount()"
            >
              <ion-icon name="chevron-forward" slot="end"></ion-icon>
              {{'login.valid'|translate}}
            </ion-button>
          </div>
        </form>
      </ng-container>

      <ng-container *ngIf="state == 'thanks'">
        <div class="allWidth first-explication flex-col-center">
          <ion-img
            class="img-giorgio"
            src="assets/images/characters/specific/gourmand.png"
          ></ion-img>
        </div>
        <h2>{{text_thanks | translate}}</h2>
        <ion-button
          shape="round"
          fill="outline"
          color="white"
          size="large"
          (click)="dismiss()"
        >
          <ion-icon name="close" size="large" slot="icon-only"></ion-icon>
        </ion-button>
      </ng-container>
    </div>
  </div>
</ion-content>
