import { Component, OnInit, HostListener, Input } from '@angular/core';
import { ServicesService } from '@app/services/services.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-pay-question',
  templateUrl: './pay-question.page.html',
  styleUrls: ['./pay-question.page.scss'],
})
export class PayQuestionPage implements OnInit {
  randomImage: string = '';
  state: string = 'question';
  validations_form_email: FormGroup;
  validations_form_text: FormGroup;

  constructor(
    public services: ServicesService,
    private formBuilder: FormBuilder
  ) {
    this.randomImage = this.services.utilsService.randomImagePsychology();

    this.validations_form_email = this.formBuilder.group({
      email: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(255),
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ])
      ),
    });
    this.validations_form_text = this.formBuilder.group({
      text: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(1000),
          Validators.minLength(1),
        ])
      ),
    });
  }

  ngOnInit() {}

  ionViewWillEnter() {
    const modalState = {
      modal: true,
      desc: 'fake state for our modal',
    };
    history.pushState(modalState, null!);
  }

  ionViewWillLeave() {
    if (window.history.state.modal) {
      window.history.back();
    }
  }

  emailDiscount() {
    let data = JSON.stringify({
      unique_identifier: this.services.sessionService.unique_identifier,
      email: this.services.sessionService.user.email,
    });

    this.services.apiService.post('email/discount', data).subscribe((data) => {
      console.log('Log');
    });
    this.text_thanks = 'pay-question.thanks-discount';

    this.state = 'thanks';
  }

  emailText() {
    if (!this.validations_form_text.valid) {
      this.validations_form_text.markAllAsTouched();
      return;
    }
    let text = this.validations_form_text.controls['text'].value;

    this.services.apiService.log(
      'Paywall title : ' + this.title,
      'Paywall text : ' + text
    );

    this.state = 'thanks';
  }

  title: string;
  text_thanks: string = 'pay-question.thanks';
  clickButton(state) {
    this.services.storageService.set('pay-question', true);
    switch (state) {
      case 'think':
        this.services.apiService.log('Paywall :IThink', 'Paywall : IThink');
        this.state = 'thanks';
        break;
      case 'useful':
        this.services.apiService.log(
          'Paywall :Not useful',
          'Paywall : No useful'
        );
        this.state = 'text';
        this.title = 'pay-question.notUsefulTitle';
        break;
      case 'complex':
        this.services.apiService.log(
          'Paywall : Too complex',
          'Paywall : Too complex'
        );
        this.state = 'text';
        this.title = 'pay-question.tooComplexTitle';
        break;
      case 'expensive':
        this.services.apiService.log(
          'Paywall : Too expensive',
          'Paywall : Too expensive'
        );
        if (this.services.sessionService.user != null) this.emailDiscount();
        this.state = 'thanks';
        break;
      case 'other':
        this.services.apiService.log('Paywall : Other', 'Paywall : Other');
        this.state = 'text';
        this.title = 'pay-question.otherTitle';
        break;
    }
  }

  number_of_rows: number = 2;

  ontextChange(event) {
    let text = this.validations_form_text.controls['text'].value;
    if (text == null) {
      text = '';
    }
    let numberOfLinesBreaks = (text.match(/\n/g) || []).length;
    this.number_of_rows = this.services.utilsService.clamp(
      numberOfLinesBreaks + text.length / 50 + 2,
      2,
      100
    );
  }

  @HostListener('window:popstate', ['$event'])
  async dismiss(role: string = 'dismiss') {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    const modal = await this.services.modalController.getTop();
    if (modal) {
      this.services.modalController.dismiss(null, role);
      return;
    }
  }
}
