import { Component, OnInit, HostListener, Input } from '@angular/core';
import { ServicesService } from '@app/services/services.service';

@Component({
  selector: 'app-explication',
  templateUrl: './explication.page.html',
  styleUrls: ['./explication.page.scss'],
})
export class ExplicationPage implements OnInit {
  @Input() title: string = '';
  @Input() content: string = '';
  @Input() image_or_icon: string = null;
  randomImage: string = '';

  giorgio: string[] = ['cuisinier', 'explorateur', 'math', 'encouragement'];
  emotion_giorgio: string = 'cuisinier';

  constructor(public services: ServicesService) {
    this.randomImage = this.services.utilsService.randomImagePsychology();

    this.emotion_giorgio =
      this.giorgio[
        this.services.utilsService.randomNumber(0, this.giorgio.length - 1)
      ];
  }

  ngOnInit() {}

  ionViewWillEnter() {
    const modalState = {
      modal: true,
      desc: 'fake state for our modal',
    };
    history.pushState(modalState, null!);
  }

  ionViewWillLeave() {
    if (window.history.state.modal) {
      window.history.back();
    }
  }

  @HostListener('window:popstate', ['$event'])
  async dismiss(role: string = 'dismiss') {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    const modal = await this.services.modalController.getTop();
    if (modal) {
      this.services.modalController.dismiss(null, role);
      return;
    }
  }
}
