export class Data {
  public static missions = [
    {
      title: {
        fr: 'Préparer et amener le petit déjeuner au lit',
        en: 'Prepare and bring the breakfast in bed',
        de: 'Bereite das Frühstück vor und bringe es ans Bett',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1504754524776-8f4f37790ca0',
      },
      budget: 1,
      time: 0,
      categories: {
        touch: false,
        service: true,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: '8 idées de déjeuner au lit du dimanche matin',
            type: 'link',
            url: 'https://www.lifemadedelicious.ca/collections/top10/e/8-sunday-morning-breakfast-in-bed-ideas?sc_lang=fr-CA',
          },
          en: {
            title: '48 extra-special recipes for Breakfast in Bed',
            type: 'link',
            url: 'https://www.tasteofhome.com/collection/breakfast-in-bed-recipes/',
          },
          de: {
            title: '9 Tipps für ein besonderes Frühstück im Bett',
            type: 'link',
            url: 'https://www.brigitte.de/rezepte/kochtipps/fruehstueck-im-bett--tipps--ideen-und-rezepte-12232036.html',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Faire un massage de 10 minutes du dos',
        en: 'Do a 10-minute back massage',
        de: 'Gib eine 10-minütige Rückenmassage',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1544161515-4ab6ce6db874',
      },
      budget: 0,
      time: 0,
      categories: {
        touch: true,
        service: true,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: false,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: true,
        number: true,
        partner_pleasure: true,
        my_pleasure: false,
        duree: true,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Comment faire un massage du dos ?',
            type: 'link',
            url: 'https://www.lesjardins-suspendus.com/comment-faire-un-bon-massage-du-dos/#:~:text=Pour%20la%20technique%2C%20massez%20de,vos%20doigts%20et%20descendez%20doucement.',
          },
          en: {
            title: 'How to make a good back massage ?',
            type: 'link',
            url: 'https://www.drugs.com/cg/how-to-give-a-back-massage.html',
          },
          de: {
            title: 'Wie gibt man eine gute Rückenmassage?',
            type: 'link',
            url: 'https://origin-massage.ch/blog/wie-massiert-man-den-ruecken-richtig',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Préparer un petit cadeau',
        en: 'Prepare a little gift',
        de: 'Bereite ein kleines Geschenk vor',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1521478706270-f2e33c203d95',
      },
      budget: 1,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: true,
        word: false,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: {
        fr: {
          title: 'Petites attentions pour renforcer l‘amour',
          type: 'link',
          url: 'https://www.letsfamily.ch/fr/petites-attentions-pour-renforcer-lamour',
        },
        en: {
          title:
            "31 New Relationship Gift Ideas That'll Further Your Connection",
          type: 'link',
          url: 'https://www.theknot.com/content/gifts-for-new-relationship',
        },
        de: {
          title: 'Kleine Aufmerksamkeiten, um die Liebe zu stärken',
          type: 'link',
          url: 'https://www.letsfamily.ch/de/kleine-aufmerksamkeiten-fuer-die-liebe',
        },
      },
    },
    {
      title: {
        fr: 'Aller manger dans un restaurant',
        en: 'Go eat at a restaurant',
        de: 'Gehe in ein Restaurant essen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1517248135467-4c7edcad34c4',
      },
      budget: 2,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: true,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title:
              'Quels critères prendre en compte pour choisir un bon restaurant ?',
            type: 'link',
            url: 'https://la-fine-bouche.fr/9122_Quels-criteres-prendre-en-compte-pour-choisir-un-bon-restaurant-.html',
          },
          en: {
            title: '10 things to consider when choosing a restaurant',
            type: 'link',
            url: 'https://medium.com/@johnvilas504_1438/10-things-to-consider-when-choosing-a-restaurant-31e2e5b94176',
          },
          de: {
            title: '10 Kriterien, um ein gutes Restaurant zu wählen',
            type: 'link',
            url: 'https://www.stern.de/genuss/essen/restaurant--10-anzeichen--woran-sie-ein-gutes-lokal-erkennen-34595982.html',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Ecrire un poème',
        en: 'Write a poem',
        de: 'Ein Gedicht schreiben',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1520342059593-39c80c453571',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: true,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Comment écrire un poème ?',
            type: 'link',
            url: 'https://www.edilivre.com/8-conseils-pour-ecrire-un-poeme/',
          },
          en: {
            title: 'How to write a poem ?',
            type: 'link',
            url: 'https://www.grammarly.com/blog/how-to-write-a-poem/',
          },
          de: {
            title: 'Gedichte schreiben – Tipps und Tricks',
            type: 'link',
            url: 'https://frieling.de/Schreibtipps/Gedichte-schreiben',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Aller boire un verre ensemble',
        en: 'Go have a drink together',
        de: 'Zusammen etwas trinken gehen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1514361892635-6b07e31e75f9',
      },
      budget: 1,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
      },
      actions: [
        {
          fr: {
            title: 'Comment choisir un bon bar',
            type: 'link',
            url: 'https://www.restaurant-lacasagaia.fr/choisir-bar-passer-soiree-agreable-amis.php',
          },
          en: {
            title: 'What makes a bar great?',
            type: 'link',
            url: 'https://www.diffordsguide.com/encyclopedia/590/bars/what-makes-a-great-bar-great',
          },
          de: {
            title: 'Fünf besondere Merkmale einer guten Bar',
            type: 'link',
            url: 'https://mixology.eu/fuenf-mal-gute-bar/',
          },
        },
      ],
    },
    {
      title: {
        fr: "Nettoyer l'appartement",
        en: 'Clean your flat',
        de: 'Die Wohnung putzen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1583907659441-addbe699e921',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: true,
        moment: false,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: false,
        eros: false,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: true,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
      },
      actions: [
        {
          fr: {
            title: 'Nettoyez votre appartement en 2 heures',
            type: 'link',
            url: 'https://www.helpling.fr/blog/nettoyez-votre-appartement-en-deux-heures-les-conseils-de-notre-expert-pour-un-appartement-etincelant/',
          },
          en: {
            title: 'How to quickly clean your home',
            type: 'link',
            url: 'https://www.wikihow.com/Clean-Your-House-or-Apartment-Quickly-and-Effectively',
          },
          de: {
            title: 'Wohnung in zwei Stunden putzen',
            type: 'link',
            url: 'https://www.helpling.de/blog/wohnung-in-zwei-stunden-putzen-die-experten-tipps-fur-eine-blitzsaubere-wohnung/',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Préparer un bon repas',
        en: 'Prepare a good meal',
        de: 'Ein gutes Essen zubereiten',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1464500542410-1396074bf230',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: false,
        service: true,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: true,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: '15 idées recettes',
            type: 'link',
            url: 'https://www.marieclaire.fr/cuisine/diner-romantique-15-recettes-faciles-pour-impressionner-son-date,1457290.asp',
          },
          en: {
            title: '32 recipe ideas',
            type: 'link',
            url: 'https://www.delicious.com.au/recipes/collections/gallery/70-recipes-for-a-romantic-date-night-at-home/9d4s0yiz',
          },
          de: {
            title: '4791 leckere und einfache Rezepte',
            type: 'link',
            url: 'https://www.lidl-kochen.de/rezeptwelt/einfach/',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Aller faire les commissions',
        en: 'Go grocery shopping',
        de: 'Lebensmittel einkaufen gehen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1525328437458-0c4d4db7cab4',
      },
      budget: 2,
      time: 1,
      categories: {
        touch: false,
        service: true,
        moment: false,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: false,
        eros: false,
        ludus: false,
        storge: true,
        finance: true,
        decision: false,
        task: true,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
      },
      actions: [
        {
          fr: {
            title: 'Conseils pour faire les courses',
            type: 'link',
            url: 'https://www.marmiton.org/magazine/shopping-faire-les-courses-sans-se-ruiner-article_100091.aspx',
          },
          en: {
            title: 'Grocery shopping tips',
            type: 'link',
            url: 'https://www.thekitchn.com/10-smart-tips-for-grocery-shopping-on-a-budget-234052',
          },
          de: {
            title: 'Einkaufstipps',
            type: 'link',
            url: 'https://www.clever-konsumieren.ch/infos-tipps/einkaufstipps/',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Prendre 15 minutes pour écouter votre moitié',
        en: 'Take 15 minutes to listen to your other half',
        de: 'Nehmen Sie sich 15 Minuten Zeit, um Ihrem Partner zuzuhören',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1604881989793-466aca8dd319',
      },
      budget: 0,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
      },
      actions: [
        {
          fr: {
            title: 'Comment écouter activement',
            type: 'link',
            url: 'https://www.cchst.ca/oshanswers/psychosocial/mh/mentalhealth_activelistening.html',
          },
          en: {
            title: '10 tips for active listening',
            type: 'link',
            url: 'https://www.bhf.org.uk/informationsupport/heart-matters-magazine/wellbeing/how-to-talk-about-health-problems/active-listening',
          },
          de: {
            title:
              'Aktives Zuhören – eine verlorene Kunst: 7 Techniken & Übungen',
            type: 'link',
            url: 'https://clevermemo.com/blog/aktives-zuhoeren/',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Préparer un café pour votre partenaire',
        en: 'Prepare coffee for your partner',
        de: 'Bereiten Sie den Kaffee für Ihren Partner zu',
      },
      budget: 0,
      time: 0,
      image: {
        type: 'unsplash',
        url: 'photo-1517487881594-2787fef5ebf7',
      },
      categories: {
        touch: false,
        service: true,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
      },
      actions: [
        {
          fr: {
            title: 'Comment préparer un bon café',
            type: 'link',
            url: 'https://www.larbreacafe.com/blogs/le-blog/guide-pratique-comment-faire-un-bon-cafe-n17',
          },
          en: {
            title: 'How to make a good coffee',
            type: 'link',
            url: 'https://www.caffeluxxe.com/blogs/news/11-tips-for-brewing-better-coffee-at-home#/',
          },
          de: {
            title: 'Kaffeezubereitung: 6 Methoden um guten Kaffee zuzubereiten',
            type: 'link',
            url: 'https://unbound.cc/blogs/kaffeewissen/kaffeezubereitung-6-methoden-kaffee-zuzubereiten',
          },
        },
      ],
    },
    {
      title: {
        fr: "Presser un jus d'orange frais pour votre partenaire",
        en: 'Squeeze fresh orange juice for your partner',
        de: 'Frischen Orangensaft für Ihren Partner pressen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1497706764541-6fc3e90e337e',
      },
      budget: 1,
      time: 0,
      categories: {
        touch: false,
        service: true,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
      },
      actions: [
        {
          fr: {
            title: "Comment presser simplement un jus d'orange",
            type: 'link',
            url: 'https://www.marmiton.org/astuces/cette-astuce-permet-de-presser-facilement-vos-citrons-et-oranges-si-vous-n-avez-pas-de-presse-agrume-s4074167.html',
          },
          en: {
            title: 'Orange juice recipe',
            type: 'link',
            url: 'https://www.indianhealthyrecipes.com/orange-juice-recipe/',
          },
          de: {
            title: "Orangen auspressen: Mehr Saft gibt's mit diesen Tricks",
            type: 'link',
            url: 'https://www.dole.com/de-de/blog/nutrition/squeezing-oranges-tips-tricks-get-juice',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Cuisiner un repas gastronomique',
        en: 'Cook a gourmet meal',
        de: 'Ein Gourmetessen kochen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1601001815853-3835274403b3',
      },
      budget: 1,
      time: 2,
      categories: {
        touch: false,
        service: true,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: '11 idées recettes',
            type: 'link',
            url: 'https://www.elle.fr/Elle-a-Table/Les-dossiers-de-la-redaction/Dossier-de-la-redac/Menu-gastronomique',
          },
          en: {
            title: '16 recipe ideas',
            type: 'link',
            url: 'https://www.google.com/search?q=cook+gourmet+meal+recipes+at+home&sca_esv=84e39f926da01c5e&sxsrf=ADLYWIKPyBXgIMofALGX9l9XjAb_Vehgog%3A1722762739232&ei=80WvZt_YDfm3i-gP0NSG2AM&ved=0ahUKEwifnuqo_9qHAxX52wIHHVCqATsQ4dUDCBA&uact=5&oq=cook+gourmet+meal+recipes+at+home&gs_lp=Egxnd3Mtd2l6LXNlcnAiIWNvb2sgZ291cm1ldCBtZWFsIHJlY2lwZXMgYXQgaG9tZTIFECEYoAEyBRAhGKABSIkIUE1YpAdwAXgBkAEAmAF4oAHaBKoBAzQuMrgBA8gBAPgBAZgCB6AC9ATCAgoQABiwAxjWBBhHwgIFECEYnwXCAgQQIRgVmAMAiAYBkAYIkgcDNC4zoAeKIA&sclient=gws-wiz-serp',
          },
          de: {
            title: '16 Rezeptideen',
            type: 'link',
            url: 'https://www.chefkoch.de/rs/s0/gourmet+essen/Rezepte.html',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Organiser un picnic',
        en: 'Organize a picnic',
        de: 'Ein Picknick organisieren',
      },
      budget: 1,
      time: 2,
      image: {
        type: 'unsplash',
        url: 'photo-1593034509785-5b17ba49f683',
      },
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Comment organiser un picnic',
            type: 'link',
            url: 'https://www.marmiton.org/magazine/tendances-gourmandes/comment-organiser-un-pique-nique-parfait-sans-se-prendre-la-tete-article_100001.html',
          },
          en: {
            title: 'How to organize a picnic',
            type: 'link',
            url: 'https://www.bbcgoodfood.com/howto/guide/how-plan-perfect-picnic',
          },
          de: {
            title: 'Wie man ein Picknick organisiert',
            type: 'link',
            url: 'https://www.essen-und-trinken.de/picknick',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Regarder le film préféré de votre partenaire',
        en: "Watch your partner's favorite movie",
        de: 'Den Lieblingsfilm Ihres Partners ansehen',
      },
      budget: 0,
      time: 1,
      image: {
        type: 'unsplash',
        url: 'photo-1478720568477-152d9b164e26',
      },
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: false,
        ludus: false,
        storge: true,
        finance: false,
        decision: true,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Les meilleurs films',
            type: 'link',
            url: 'https://www.imdb.com/chart/top/',
          },
          en: {
            title: 'Best 250 movies',
            type: 'link',
            url: 'https://www.imdb.com/chart/top/',
          },
          de: {
            title: 'Die besten filme',
            type: 'link',
            url: 'https://www.filmstarts.de/kritiken/besten/filme/',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Laisser votre partenaire choisir un film',
        en: 'Let your partner choose a movie',
        de: 'Lassen Sie Ihren Partner einen Film auswählen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1485846234645-a62644f84728',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: false,
        ludus: false,
        storge: true,
        finance: false,
        decision: true,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Manger des popcorns au lit',
        en: 'Eat popcorn in bed',
        de: 'Popcorn im Bett essen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1523207911345-32501502db22',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: false,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: true,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Regarder un épisode de la série préférée de votre partenaire',
        en: "Watch one episode of your partner's favorite series",
        de: 'Eine Folge der Lieblingsserie Ihres Partners ansehen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1517604931442-7e0c8ed2963c',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: false,
        ludus: false,
        storge: true,
        finance: false,
        decision: true,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Laisser votre partenaire choisir un épisode de série à regarder ensemble',
        en: 'Let your partner choose an episode of a series',
        de: 'Lassen Sie Ihren Partner eine Folge einer Serie auswählen',
      },
      budget: 0,
      time: 1,
      image: {
        type: 'unsplash',
        url: 'photo-1489599849927-2ee91cede3ba',
      },
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: false,
        ludus: false,
        storge: true,
        finance: false,
        decision: true,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Prendre un bain ensemble',
        en: 'Take a bath together',
        de: 'Zusammen ein Bad nehmen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1507652313519-d4e9174996dd',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: false,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: false,
        number: true,
        partner_pleasure: true,
        my_pleasure: true,
        duree: true,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Les astuces pour un bain super romantique',
            type: 'link',
            url: 'https://www.vegaooparty.com/blog/a-idees-bain-en-amoureux-st-valentin.html#:~:text=%23%20Bain%20romantique%20en%20amoureux%20%3A%20des,aux%20rideaux%20et%20aux%20meubles.',
          },
          en: {
            title: '6 Ideas For Creating A Romantic Bathroom',
            type: 'link',
            url: 'https://www.cranleighbathroomstudios.co.uk/blog/the-blog/6-ideas-for-creating-a-romantic-bathroom',
          },
          de: {
            title: 'Ein romantisches Badezimmer gestalten',
            type: 'link',
            url: 'https://www.megabad.com/magazin/inspiration/badideen/badezimmer-romantisch/',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Prendre une douche ensemble',
        en: 'Take a shower together',
        de: 'Zusammen duschen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1560185127-bdf08e449371',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: false,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: false,
        number: true,
        partner_pleasure: true,
        my_pleasure: true,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title:
              'Les bienfaits de prendre une douche ensemble pour un couple',
            type: 'link',
            url: 'https://dulmo.store/blogs/infos/les-raisons-de-se-doucher-a-deux#:~:text=Prendre%20une%20douche%20%C3%A0%20deux%20peut%20participer%20%C3%A0%20alimenter%20la,la%20communication%20dans%20le%20couple.',
          },
          en: {
            title: 'Benefits of Showering Together for Couples',
            type: 'link',
            url: 'https://gethai.com/blogs/thesource/6-benefits-of-showering-with-a-partner#:~:text=Many%20couples%20find%20this%20intimate,foundations%20of%20trust%20and%20safety.',
          },
          de: {
            title: '7 Gründe, warum gemeinsames Duschen gut für die Intimität',
            type: 'link',
            url: 'https://www.bonobology.com/de/gemeinsam-duschen/',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Jouer à un jeu de société ensemble',
        en: 'Play a board game together',
        de: 'Ein Brettspiel zusammen spielen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1457694716743-eb419114c894',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: false,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Les 40 meilleurs jeux de société pour jouer en couple',
            type: 'link',
            url: 'https://www.ludum.fr/blog/les-40-meilleurs-jeux-de-societe-pour-jouer-en-couple-n17',
          },
          en: {
            title: 'The best board games to play as a couple',
            type: 'link',
            url: 'https://www.wargamer.com/couples-board-games',
          },
          de: {
            title: 'Die besten Brettspiele für Paare',
            type: 'link',
            url: 'https://www.abenteuer-brettspiele.de/top-listen/top-10-valentinstag-brettspiele-paare',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Jouer à un jeu vidéo ensemble',
        en: 'Play a video game together',
        de: 'Ein Videospiel zusammen spielen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1493711662062-fa541adb3fc8',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: false,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Les 17 meilleurs jeux vidéo à jouer en couple',
            type: 'link',
            url: 'https://www.redbull.com/ch-fr/meilleurs-jeux-coop-sofa',
          },
          en: {
            title: 'The best video games to play as a couple',
            type: 'link',
            url: 'https://www.gamesradar.com/best-video-games-for-couples-date-night/',
          },
          de: {
            title: 'Die besten Videospiele, die man als Paar spielen kann',
            type: 'link',
            url: 'https://www.redbull.com/ch-de/beste-koop-games-fuer-paare',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Créer un album photo',
        en: 'Create a photo album',
        de: 'Ein Fotoalbum erstellen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1500051638674-ff996a0ec29e',
      },
      budget: 2,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Comment créer un album photo',
            type: 'link',
            url: 'https://www.photobox.fr/creation-album-photo',
          },
          en: {
            title: 'How to create a photo album',
            type: 'link',
            url: 'https://www.wikihow.com/Create-a-Photo-Album',
          },
          de: {
            title: '5 Tipps für das perfekte Fotobuch',
            type: 'link',
            url: 'https://www.youtube.com/watch?v=kNXf548xe_U',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Faire une photo souvenir',
        en: 'Take a memory photo',
        de: 'Ein Erinnerungsfoto machen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1601134991665-a020399422e3',
      },
      budget: 0,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: false,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: '5 bonnes idées pour avoir des photos souvenir',
            type: 'link',
            url: 'https://www.abcsalles.com/mag/inspiration/bonnes-idees-photos-souvenir-ultra-spontanees-evenement',
          },
          en: {
            title:
              'Spontaneous photos: Five secrets to bringing your photos to life',
            type: 'link',
            url: 'https://photor.org/spontaneous-photos-five-secrets-to-bringing-your-photos-to-life/',
          },
          de: {
            title: '5 unschlagbare Tipps',
            type: 'link',
            url: 'https://kameramama.com/blog/5tips-naturliche-familienfotos',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Allez courir ensemble',
        en: 'Go for a run together',
        de: 'Zusammen laufen gehen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1476480862126-209bfaa8edc8',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Visiter un musée ensemble',
        en: 'Visit a museum together',
        de: 'Ein Museum zusammen besuchen',
      },
      budget: 1,
      time: 2,
      image: {
        type: 'unsplash',
        url: 'photo-1514905552197-0610a4d8fd73',
      },
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: false,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: "Visiter une galerie d'art ensemble",
        en: 'Visit an art gallery together',
        de: 'Eine Kunstgalerie zusammen besuchen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1507643179773-3e975d7ac515',
      },
      budget: 1,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Aller à un concert',
        en: 'Go to a live concert',
        de: 'Zu einem Live-Konzert gehen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1429514513361-8fa32282fd5f',
      },
      budget: 2,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: "Aller à un spectacle d'humour",
        en: 'Go to a comedy show',
        de: 'Zu einer Comedy-Show gehen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1506882482183-3fc779ba50f4',
      },
      budget: 2,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Aller à la plage',
        en: 'Go to the beach',
        de: 'An den Strand gehen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1507525428034-b723cf961d3e',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Aller dans un SPA',
        en: 'Go to a SPA',
        de: 'In ein Spa gehen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1583417267826-aebc4d1542e1',
      },
      budget: 3,
      time: 2,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: true,
        my_pleasure: true,
        duree: true,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Organiser un massage de couple',
        en: 'Organize a couples massage',
        de: 'Eine Paarmassage organisieren',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1598901865264-4f5f30954532',
      },
      budget: 3,
      time: 2,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Aller au cinéma',
        en: 'Go to the cinema',
        de: 'Ins Kino gehen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1513106580091-1d82408b8cd6',
      },
      budget: 2,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: true,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Faire un tour à vélo',
        en: 'Go for a bike ride together',
        de: 'Eine Fahrradtour machen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1563015790-1ea236454d8f',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Aller à un cours de danse',
        en: 'Go to a dance lesson',
        de: 'Einen Tanzkurs besuchen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1504609813442-a8924e83f76e',
      },
      budget: 2,
      time: 1,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: true,
        my_pleasure: true,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Quelles sont les différentes danses de couple ?',
            type: 'link',
            url: 'https://www.salsadanse.com/danse-de-salon-les-differentes-danses-de-couple/',
          },
          en: {
            title: '20+ Types of Dance: Every Major Style, Easily Explained',
            type: 'link',
            url: 'https://sophiainsapphire.com/types-of-dance/',
          },
          de: {
            title: 'Welcher Paartanz passt zu mir?',
            type: 'link',
            url: 'https://www.consumo.ch/news-freizeit-fitness/welcher-paartanz-passt-zu-mir-2757',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Faire une randonnée',
        en: 'Go hiking together',
        de: 'Gemeinsam wandern',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1528980065671-e7d4a10c6a55',
      },
      budget: 0,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Visiter un zoo',
        en: 'Visit a zoo',
        de: 'Einen Zoo besuchen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1669120180498-652eb656193c',
      },
      budget: 1,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: false,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Faire un compliment',
        en: 'Give a compliment',
        de: 'Ein Kompliment machen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1500964757637-c85e8a162699',
      },
      budget: 0,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: false,
        word: true,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Comment oser faire un compliment',
            type: 'link',
            url: 'https://www.laao.life/blog/comment-oser-faire-des-compliments.html',
          },
          en: {
            title: 'How to Give a Compliment',
            type: 'link',
            url: 'https://www.wikihow.com/Give-a-Compliment#:~:text=Come%20up%20with%20something%20genuine,t%20say%20anything%20at%20all.&text=Focus%20on%20achievements%20more%20than,awesome%20achievements%20or%20personal%20qualities.',
          },
          de: {
            title: 'Wie man richtig gute Komplimente macht',
            type: 'link',
            url: 'https://www.barmer.de/gesundheit-verstehen/familie/partnerschaft/komplimente-1071174',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Dire "Je t\'aime"',
        en: 'Say "I Love you"',
        de: '"Ich liebe dich" sagen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1515890435782-59a5bb6ec191',
      },
      budget: 0,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: false,
        word: true,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Comment dire "Je t\'aime"',
            type: 'link',
            url: 'https://www.yoursurprise.fr/blog/pour-vous/comment-dire-je-t-aime',
          },
          en: {
            title: 'How to Tell Someone You Love Them',
            type: 'link',
            url: 'https://www.cosmopolitan.com/sex-love/a41529544/how-to-tell-someone-you-love-them/',
          },
          de: {
            title: 'Daran erkennst du, ob du bereit für die 3 Worte bist',
            type: 'link',
            url: 'https://www.brigitte.de/liebe/beziehung/beziehung--5-anzeichen--dass-es-zu-frueh-ist---ich-liebe-dich--zu-sagen-11666672.html',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Aller au fitness ensemble',
        en: 'Go to the gym together',
        de: 'Gemeinsam ins Fitnessstudio gehen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1483721310020-03333e577078',
      },
      budget: 2,
      time: 2,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: true,
        frustration: true,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Organiser un cours de cuisine',
        en: 'Organize a cooking class',
        de: 'Einen Kochkurs organisieren',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1491185841098-9ce20f966624',
      },
      budget: 3,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: true,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Pratiquer une position sexuelle différente',
        en: 'Try a new sexual position',
        de: 'Eine neue sexuelle Position ausprobieren',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1522771739844-6a9f6d5f14af',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: true,
        number: true,
        partner_pleasure: true,
        my_pleasure: true,
        duree: true,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: '11 positions sexuelles à essayer',
            type: 'link',
            url: 'https://www.salutbonjour.ca/2020/01/30/les-11-positions-du-kamasutra-a-essayer',
          },
          en: {
            title: '56 fun positions to try',
            type: 'link',
            url: 'https://www.menshealth.com/sex-women/a19547362/45-sex-positions-guys-should-know/',
          },
          de: {
            title: 'Diese 12 Dinge solltest du wirklich im Bett ausprobieren',
            type: 'link',
            url: 'https://www.menshealth.de/sex/sextipps-die-sie-wirklich-ausprobieren-sollten/',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Faire du yoga ensemble',
        en: 'Do yoga together',
        de: 'Gemeinsam Yoga machen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1510894347713-fc3ed6fdf539',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: true,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Yoga gratuit en ligne',
            type: 'link',
            url: 'https://mon.yoga/blog/cours-de-hatha-yoga-en-ligne-gratuits-en-francais/',
          },
          en: {
            title: 'Free online yoga',
            type: 'link',
            url: 'https://yogawithadriene.com/',
          },
          de: {
            title: 'YOGA für Anfänger | 20 Minuten Home Workout',
            type: 'link',
            url: 'https://www.youtube.com/watch?v=UErN1VLCXC0',
          },
        },
      ],
    },
    {
      title: {
        fr: "Faire de l'acroyoga ensemble",
        en: 'Do acro-yoga together',
        de: 'Gemeinsam Acro-Yoga machen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1683172346949-129ba01bffa0',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: true,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: "Comment faire de l'acroyoga",
            type: 'link',
            url: 'http://acroyogaworld.fr/5-poses-acroyoga-facile-pour-debuter',
          },
          en: {
            title: 'How to do acroyoga',
            type: 'link',
            url: 'https://www.yogaemotion.net/acroyoga-for-beginners',
          },
          de: {
            title: 'Acroyoga: Alles über den Yoga-Stil',
            type: 'link',
            url: 'https://www.lotuscrafts.com/blogs/blog/acroyoga-uebungen',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Mettre de la musique et danser',
        en: 'Put on some music and dance',
        de: 'Musik auflegen und tanzen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1481653125770-b78c206c59d4',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: true,
        number: false,
        partner_pleasure: true,
        my_pleasure: true,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: "Prendre 15 minutes et écouter totalement l'autre",
        en: 'Take 15 minutes and fully listen',
        de: 'Nehmen Sie sich 15 Minuten Zeit und hören Sie vollständig zu',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1606245081924-c08c2c463d2b',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: true,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: true,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Comment pratiquer l’écoute active',
            type: 'link',
            url: 'https://www.workshoppratique.ch/blogue/detail/article/communication-comment-pratiquer-lecoute-active/',
          },
          en: {
            title: '7 Active Listening Techniques For Better Communication',
            type: 'link',
            url: 'https://www.verywellmind.com/what-is-active-listening-3024343',
          },
          de: {
            title: 'Aktives Zuhören: Die wichtigsten Tipps im Überblick',
            type: 'link',
            url: 'https://asana.com/de/resources/active-listening',
          },
        },
      ],
    },

    {
      title: {
        fr: 'Aller camper en forêt',
        en: 'Go camping in the forest',
        de: 'Im Wald campen gehen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1504280390367-361c6d9f38f4',
      },
      budget: 1,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: true,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: '10 conseils pour le bivouac',
            type: 'link',
            url: 'https://www.transa.ch/fr/blog/camping/questions-reponses-bivouac/',
          },
          en: {
            title: '7 Tips for Camping in the Forest for Beginners',
            type: 'link',
            url: 'https://maria-aziz.medium.com/7-tips-for-camping-in-the-forest-for-beginner-4908af44d95b',
          },
          de: {
            title: 'Wildcampen – 9 Tipps',
            type: 'link',
            url: 'https://www.bergreif.de/2015/05/18/wildcampen-wild-zelten/',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Regarder le coucher de soleil ensemble',
        en: 'Watch the sunset together',
        de: 'Den Sonnenuntergang zusammen anschauen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1422493757035-1e5e03968f95',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Faire un concours de cuisine',
        en: 'Hold a cooking competition',
        de: 'Einen Kochwettbewerb veranstalten',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1512685076844-4161e436c4f5',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Partir dehors en exploration',
        en: 'Go explore outside',
        de: 'Draußen erkunden gehen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1562440469-fc4588d94d0d',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Aller faire du shopping',
        en: 'Go shopping',
        de: 'Einkaufen gehen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1534452203293-494d7ddbf7e0',
      },
      budget: 3,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: false,
        finance: true,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Poser 3 questions coquines',
        en: 'Ask 3 naughty questions',
        de: 'Stelle 3 freche Fragen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1529245814698-dd66c442bfef',
      },
      budget: 0,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: false,
        word: true,
        stress: false,
        energy: true,
        connection: true,
        eros: false,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: true,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: '30 questions sexuelles (et sensuelles)',
            type: 'link',
            url: 'https://www.salutbonjour.ca/2020/04/28/16-questions-coquines-a-poser-a-votre-partenaire-pour-garder-la-flamme-a-distance',
          },
          en: {
            title: '150 Sexy Questions',
            type: 'link',
            url: 'https://www.mindbodygreen.com/articles/sexy-questions-to-ask',
          },
          de: {
            title: '30 sexy Fragen',
            type: 'link',
            url: 'https://www.beziehungsweise-magazin.de/ratgeber/sex-erotik/30-sexy-fragen-an-ihren-neuen-partner/',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Apprendre quelque chose à son partenaire',
        en: 'Teach something to your partner',
        de: 'Meinem Partner etwas beibringen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1624809921074-3e6d98503586',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: false,
        energy: false,
        connection: true,
        eros: false,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Ces 20 infos que vous ignorez surement',
            type: 'link',
            url: 'https://www.elle.fr/Loisirs/News/Le-saviez-vous-ces-20-infos-que-vous-ignorez-surement-3760281',
          },
          en: {
            title: '101 random fun facts that will blow your mind',
            type: 'link',
            url: 'https://www.sciencefocus.com/science/fun-facts',
          },
          de: {
            title:
              'Unnützes Wissen: Von Ausbildungsberufen der Stars bishin zu Statistiken',
            type: 'link',
            url: 'https://www.perspektiven-finden.com/tipps/unnuetzes-sinnloses-wissen',
          },
        },
      ],
    },

    {
      title: {
        fr: 'Faire une blague marrante',
        en: 'Crack a funny joke',
        de: 'Einen lustigen Witz erzählen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1620459950844-3c668c24a103',
      },
      budget: 0,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: false,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Blagues marrantes à raconter',
            type: 'link',
            url: 'https://www.blague-drole.net/',
          },
          en: {
            title: 'Funny jokes to tell',
            type: 'link',
            url: 'https://www.rd.com/list/short-jokes/',
          },
          de: {
            title: 'Lustige Witze zum Erzählen',
            type: 'link',
            url: 'https://karrierebibel.de/witz-erzaehlen/',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Organiser un karaoké',
        en: 'Organize a karaoke night',
        de: 'Ein Karaoke organisieren',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1525431301226-f824535d4953',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Comment organiser un karaoké',
            type: 'link',
            url: 'https://www.karafun.fr/blog/1135-comment-organiser-le-karaoke-parfait-pour-toute-la-famille.html',
          },
          en: {
            title: 'How to organize a karaoke night',
            type: 'link',
            url: 'https://www.ezcast.com/blog/12662/8-pro-tips-to-host-an-awesome-karaoke-party',
          },
          de: {
            title: 'Wie man einen Karaoke-Abend organisiert',
            type: 'link',
            url: 'https://einklang.de/karaoke-party-tipps/',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Choisir un livre à lire à 2',
        en: 'Choose a book to read together',
        de: 'Ein Buch zum gemeinsamen Lesen auswählen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1457369804613-52c61a468e7d',
      },
      budget: 1,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: false,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Livres à lire en couple',
            type: 'link',
            url: 'https://lefacteurlivre.com/blog/conseils/quel-livre-lire-couple/',
          },
          en: {
            title: 'Books to read as a couple',
            type: 'link',
            url: 'https://www.yawamoateng.com/post/20-must-read-books-for-couples-to-read-together',
          },
          de: {
            title: 'Bücher zum gemeinsamen Lesen',
            type: 'link',
            url: 'https://raumfuereuch.com/blog/buecher-produkte/lebensfreude-zu-zweit/',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Faire un concours de dessin',
        en: 'Hold a drawing competition',
        de: 'Einen Malwettbewerb veranstalten',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1616627981431-ed306bb11ac0',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: false,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Organiser une chasse au trésor',
        en: 'Organize a treasure hunt',
        de: 'Eine Schatzsuche organisieren',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1470506926202-05d3fca84c9a',
      },
      budget: 1,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Comment organiser une chasse au trésor',
            type: 'link',
            url: 'https://lesminimondes.fr/blog/activites-famille/chasse-au-tresor/',
          },
          en: {
            title: 'How to Plan a Fun Treasure Hunt',
            type: 'link',
            url: 'https://www.instructables.com/HOW-TO-PLAN-A-FUN-TREASURE-HUNT/',
          },
          de: {
            title: 'Wie man eine Schatzsuche plant',
            type: 'link',
            url: 'https://schnitzeljagd-ideen.de/schnitzeljagd-anleitung/',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Faire un SPA à la maison',
        en: 'Have a spa day at home',
        de: 'Ein Heim-Spa machen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1532926381893-7542290edf1d',
      },
      budget: 1,
      time: 3,
      categories: {
        touch: true,
        service: true,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: true,
        number: true,
        partner_pleasure: true,
        my_pleasure: true,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Un SPA à la maison en 5 étapes',
            type: 'link',
            url: 'https://ch.loccitane.com/fr-ch/une-journee-relaxante-a-la-maison',
          },
          en: {
            title: '10 Tips for a Perfect DIY Spa at Home',
            type: 'link',
            url: 'https://www.aarp.org/entertainment/style-trends/info-2018/diy-spa-night.html',
          },
          de: {
            title: '10 Tipps für ein perfektes Home-Spa',
            type: 'link',
            url: 'https://www.glamour.de/beauty/artikel/home-spa-programm',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Méditer ensemble',
        en: 'Meditate together',
        de: 'Gemeinsam meditieren',
      },
      budget: 0,
      time: 1,
      image: {
        type: 'unsplash',
        url: 'photo-1461766705442-58d58276121a',
      },
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: false,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: true,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Méditation gratuite',
            type: 'link',
            url: 'https://opensynaps.com/meditation/',
          },
          en: {
            title: 'Guided meditation',
            type: 'link',
            url: 'https://www.tarabrach.com/guided-meditations/',
          },
          de: {
            title: 'Geführte Meditation',
            type: 'link',
            url: 'https://www.youtube.com/watch?v=4Z1RPavOX3s',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Partir en balade',
        en: 'Go for a walk together',
        de: 'Gemeinsam spazieren gehen',
      },
      budget: 0,
      time: 2,
      image: {
        type: 'unsplash',
        url: 'photo-1478555718543-a87aa261dbc4',
      },
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: false,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Jouer au bowling',
        en: 'Go bowling',
        de: 'Bowling spielen',
      },
      budget: 2,
      time: 3,
      image: {
        type: 'unsplash',
        url: 'photo-1581084094544-5195e8c7bf76',
      },
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Faire du roller',
        en: 'Go rollerblading',
        de: 'Rollschuh laufen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1501782223170-a04fc18cba9e',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Aller bronzer ensemble',
        en: 'Go sunbathing together',
        de: 'Gemeinsam sonnenbaden gehen',
      },
      budget: 0,
      time: 2,
      image: {
        type: 'unsplash',
        url: 'photo-1586234004696-d70537fa5aea',
      },
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: true,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Préparer un cocktail',
        en: 'Prepare a cocktail',
        de: 'Einen Cocktail zubereiten',
      },
      budget: 1,
      time: 1,
      image: {
        type: 'unsplash',
        url: 'photo-1470337458703-46ad1756a187',
      },
      categories: {
        touch: false,
        service: true,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: true,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Recettes de cocktails',
            type: 'link',
            url: 'https://www.elle.fr/Elle-a-Table/Fiches-cuisine/Tous-les-themes/Recettes-de-cocktail',
          },
          en: {
            title: '84 easy cocktails recipes',
            type: 'link',
            url: 'https://www.bbcgoodfood.com/recipes/collection/easy-cocktail-recipes',
          },
          de: {
            title: 'Einfache Cocktailrezepte',
            type: 'link',
            url: 'https://www.koch-mit.de/kueche/cocktails/',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Aller au marché',
        en: 'Go to the market',
        de: 'Zum Markt gehen',
      },
      budget: 2,
      time: 3,
      image: {
        type: 'unsplash',
        url: 'photo-1488459716781-31db52582fe9',
      },
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: false,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: true,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Aller boire un café',
        en: 'Go have a coffee',
        de: 'Einen Kaffee trinken gehen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1506619216599-9d16d0903dfd',
      },
      budget: 2,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: true,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },

    {
      title: {
        fr: 'Jouer à action ou vérité',
        en: 'Play truth or dare',
        de: 'Wahrheit oder Pflicht spielen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1585578911801-e4f8582859a8',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Règles du jeu Action ou Vérité',
            type: 'link',
            url: 'https://fr.wikihow.com/jouer-%C3%A0-action-ou-v%C3%A9rit%C3%A9',
          },
          en: {
            title: 'Truth or Dare game rules',
            type: 'link',
            url: 'https://gamerules.com/250-truth-or-dare-questions/',
          },
          de: {
            title: 'Regeln für Wahrheit oder Pflicht',
            type: 'link',
            url: 'https://www.spielregeln.de/wahrheit-oder-pflicht.html',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Faire un pari drôle',
        en: 'Place a funny bet',
        de: 'Eine lustige Wette abschließen',
      },
      budget: 0,
      time: 0,
      image: {
        type: 'unsplash',
        url: 'photo-1646809020492-7a7d2e3688b5',
      },
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: true,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Idées de gages amusants',
            type: 'link',
            url: 'https://www.idees-de-jeux.fr/animations/idees-gages',
          },
          en: {
            title: 'Funny bet ideas',
            type: 'link',
            url: 'https://greatime.de/en/funny-bets/',
          },
          de: {
            title: 'Lustige Wettideen',
            type: 'link',
            url: 'https://www.maedchen.de/fun/wetteinsaetze-lustige-ideen-fuer-deine-freunde-und-dich-87966.html',
          },
        },
      ],
    },

    {
      title: {
        fr: "Organiser une dégustation à l'aveugle",
        en: 'Organize a blind tasting',
        de: 'Eine Blindverkostung organisieren',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1681808293763-7da363e479fe',
      },
      budget: 1,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: "Comment organiser une dégustation à l'aveugle",
            type: 'link',
            url: 'https://www.vinsocialclub.fr/magazine-vin/guide-du-vin/comment-organiser-une-degustation-de-vin-a-laveugle/',
          },
          en: {
            title: 'How to organize a blind tasting',
            type: 'link',
            url: 'https://winefolly.com/lifestyle/blind-wine-tasting-party/',
          },
          de: {
            title: 'Wie man eine Blindverkostung organisiert',
            type: 'link',
            url: 'https://blindverkostung.de/online-shop/wie-funktioniert-die-blindverkostung/#:~:text=Es%20geht%20immer%20so%20weiter,Weine%20zeigen%20stolz%20ihre%20Etiketten.',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Se dessiner mutuellement un tatouage éphémère sur la peau',
        en: 'Draw each other a temporary tattoo on your skin',
        de: 'Sich gegenseitig ein temporäres Tattoo auf die Haut zeichnen',
      },
      budget: 0,
      time: 1,
      image: {
        type: 'unsplash',
        url: 'photo-1562330764-65a98e874eb4',
      },
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Comment faire un tatouage temporaire',
            type: 'link',
            url: 'https://fr.wikihow.com/faire-un-tatouage-temporaire',
          },
          en: {
            title: 'How to make a temporary tattoo',
            type: 'link',
            url: 'https://www.wikihow.com/Make-a-Temporary-Tattoo',
          },
          de: {
            title: 'Wie man ein temporäres Tattoo macht',
            type: 'link',
            url: 'https://www.stylight.ch/Magazine/Beauty/Abziehtattoo-Selber-Machen/',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Organiser une soirée déguisée',
        en: 'Organize a fancy dress party',
        de: 'Eine Kostümparty organisieren',
      },
      budget: 2,
      time: 4,
      image: {
        type: 'unsplash',
        url: 'photo-1677746540458-66135878ebfd',
      },
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: "Aller à une soirée 90's",
        en: "Go to a 90's party",
        de: 'Zu einer 90er-Party gehen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1501426026826-31c667bdf23d',
      },
      budget: 2,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Aller à une soirée techno / electro',
        en: 'Go to a techno / electro party',
        de: 'Zu einer Techno-/Electro-Party gehen',
      },
      budget: 2,
      time: 3,
      image: {
        type: 'unsplash',
        url: 'photo-1562369083-e501b585fd2c',
      },
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Jardiner ensemble',
        en: 'Gardening together',
        de: 'Gemeinsam gärtnern',
      },
      budget: 0,
      time: 2,
      image: {
        type: 'unsplash',
        url: 'photo-1438109382753-8368e7e1e7cf',
      },
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: false,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: true,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Regarder les étoiles',
        en: 'Look at the stars',
        de: 'Die Sterne beobachten',
      },
      budget: 0,
      time: 1,
      image: {
        type: 'unsplash',
        url: 'photo-1475274047050-1d0c0975c63e',
      },
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Les différentes constellations',
            type: 'link',
            url: 'https://www.linternaute.com/sortir/guide-des-loisirs/2640097-constellations-comment-reperer-les-plus-connues-dans-le-ciel/',
          },
          en: {
            title: 'The 88 Constellations',
            type: 'link',
            url: 'https://sleepopolis.com/education/constellations-stars/',
          },
          de: {
            title: 'Wie man die Sterne beobachtet',
            type: 'link',
            url: 'https://www.sterngucker.de/sternbilder/',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Organiser un barbecue à la maison',
        en: 'Organize a BBQ at home',
        de: 'Ein BBQ zu Hause organisieren',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1466220549276-aef9ce186540',
      },
      budget: 2,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: false,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: true,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Organiser une soirée pyjama',
        en: 'Organize a pyjama party',
        de: 'Eine Pyjama-Party organisieren',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1513151233558-d860c5398176',
      },
      budget: 0,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: true,
        number: true,
        partner_pleasure: true,
        my_pleasure: true,
        duree: true,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Faire du fengshui',
        en: 'Practice feng shui',
        de: 'Feng Shui praktizieren',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1671043121840-cf607dee6152',
      },
      budget: 1,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: false,
        eros: false,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: true,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Le guide du Feng Shui',
            type: 'link',
            url: 'https://www.couleur-chanvre.com/blog/feng-shui-maison/',
          },
          en: {
            title: 'A guide to a Feng Shui house',
            type: 'link',
            url: 'https://www.realsimple.com/home-organizing/decorating/feng-shui-house',
          },
          de: {
            title: 'Ein Leitfaden für ein Feng Shui Haus',
            type: 'link',
            url: 'https://www.feng-shui.de/feng-shui-haus/',
          },
        },
      ],
    },

    {
      title: {
        fr: 'Faire des origamis',
        en: 'Do some origami together',
        de: 'Gemeinsam Origami machen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1651243181369-63ba873dabf1',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: false,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Comment faire des origamis faciles',
            type: 'link',
            url: 'https://kamidine.com/blogs/blog-papier-origami/comment-s-occuper-pendant-les-vacances-5-idees-d-origami-faciles-a-faire-avec-vos-enfants-sur-le-theme-des-animaux',
          },
          en: {
            title: 'How to make easy origami',
            type: 'link',
            url: 'https://origami.guide/instructions/easy-origami/',
          },
          de: {
            title: 'Wie man einfaches Origami macht',
            type: 'link',
            url: 'https://www.origami-kunst.de/',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Manger au lit des chips',
        en: 'Eat some chips in bed',
        de: 'Im Bett Chips essen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1576642589592-7d9778a1c9e4',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Raconter un secret',
        en: 'Tell a secret',
        de: 'Ein Geheimnis erzählen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1483706600674-e0c87d3fe85b',
      },
      budget: 0,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: true,
        word: true,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Préparer une petite attention',
        en: 'Prepare a small gesture',
        de: 'Ein kleines Geschenk vorbereiten',
      },
      budget: 1,
      time: 1,
      image: {
        type: 'unsplash',
        url: 'photo-1512909006721-3d6018887383',
      },
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: true,
        word: false,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: '20 idées de petites attentions',
            type: 'link',
            url: 'https://fr.lovebox.love/blogs/news/20-petites-attentions-de-couple-pour-entretenir-la-flamme',
          },
          en: {
            title: 'Small gesture ideas',
            type: 'link',
            url: 'https://www.theknot.com/content/gifts-for-new-relationship',
          },
          de: {
            title: 'Originelle Geschenke, die nichts kosten!',
            type: 'link',
            url: 'https://www.brigitte.de/leben/wohnen/selbermachen/originelle-geschenke--die-nichts-kosten-10205268.html',
          },
        },
      ],
    },
    {
      title: {
        fr: "S'habiller de la même façon",
        en: 'Dress the same',
        de: 'Gleich anziehen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1457052271742-6b6b66887aeb',
      },
      budget: 0,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Organiser une soirée sans écran',
        en: 'Organize a screen-free evening',
        de: 'Einen Abend ohne Bildschirm organisieren',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1464380573004-8ca85a08751a',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Offrir des fleurs',
        en: 'Give flowers',
        de: 'Blumen schenken',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1520763185298-1b434c919102',
      },
      budget: 2,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: true,
        word: false,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Offrir un bijou',
        en: 'Give a piece of jewelry',
        de: 'Ein Schmuckstück schenken',
      },
      budget: 4,
      time: 2,
      image: {
        type: 'unsplash',
        url: 'photo-1561828995-aa79a2db86dd',
      },
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: true,
        word: false,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: "Aller sur l'eau",
        en: 'Go on the water',
        de: 'Aufs Wasser gehen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1515768150303-c4734c32d5a9',
      },
      budget: 2,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Organiser un atelier pâtisserie',
        en: 'Organize a pastry workshop',
        de: 'Ein Gebäck-Workshop organisieren',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1528975604071-b4dc52a2d18c',
      },
      budget: 1,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: true,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Recettes de pâtisseries faciles',
            type: 'link',
            url: 'https://www.elle.fr/Elle-a-Table/Fiches-cuisine/Tous-les-themes/Recettes-de-patisseries',
          },
          en: {
            title: '35 Easy Pastry Recipes You Can Make at Home',
            type: 'link',
            url: 'https://www.tasteofhome.com/collection/easy-pastry-recipes-you-can-make-at-home/',
          },
          de: {
            title: 'Einfache Gebäckrezepte zum Ausprobieren',
            type: 'link',
            url: 'https://www.chefkoch.de/rs/s0/einfache+geb%C3%A4ckrezepte/Rezepte.html',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Aller manger une glace',
        en: 'Go eat some ice cream',
        de: 'Ein Eis essen gehen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1503438805992-a1f1bcaf6ec9',
      },
      budget: 1,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: true,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Aller à la patinoire',
        en: 'Go ice skating',
        de: 'Schlittschuhlaufen gehen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1517177646641-83fe10f14633',
      },
      budget: 1,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Cuisiner une pizza ensemble',
        en: 'Make a pizza together',
        de: 'Eine Pizza zusammen kochen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1513104890138-7c749659a591',
      },
      budget: 1,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: false,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: true,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Recette de pizza maison',
            type: 'link',
            url: 'https://www.hervecuisine.com/recette/recette-de-pizzas-facile/',
          },
          en: {
            title: 'How to make homemade pizza',
            type: 'link',
            url: 'https://www.loveandlemons.com/homemade-pizza/',
          },
          de: {
            title: 'Hausgemachte Pizza Rezept',
            type: 'link',
            url: 'https://www.theclub.ch/rezepte/selbstgemachte-pizza/',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Jouer au ping-pong ensemble',
        en: 'Play ping-pong together',
        de: 'Zusammen Tischtennis spielen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1609710228159-0fa9bd7c0827',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: false,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Aller aux bains thermaux',
        en: 'Go to thermal baths',
        de: 'In die Therme gehen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1664659732120-67627c483e3f',
      },
      budget: 4,
      time: 3,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: true,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: true,
        number: true,
        partner_pleasure: true,
        my_pleasure: true,
        duree: true,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Reparler de vieux souvenirs positifs',
        en: 'Talk about old positive memories',
        de: 'Über alte positive Erinnerungen sprechen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1511843511279-cfd764c09659',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: false,
        energy: false,
        connection: true,
        eros: false,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Aller faire un bain de minuit',
        en: 'Go for a midnight bath',
        de: 'Ein Mitternachtsbad nehmen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1512018611669-583e87835849',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: true,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Organiser un premier date comme si vous ne vous connaissiez pas',
        en: "Organize a first date as if you don't know each other",
        de: 'Ein erstes Date organisieren, als ob Sie sich nicht kennen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1528137727394-d69c648fe47b',
      },
      budget: 2,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: "Le premier rendez-vous: mode d'emploi",
            type: 'link',
            url: 'https://www.cosmopolitan.fr/,le-premier-rendez-vous-amoureux-mode-d-emploi,1971196.asp',
          },
          en: {
            title: 'How to organize a first date',
            type: 'link',
            url: 'https://www.wikihow.com/Plan-a-First-Date',
          },
          de: {
            title: 'Wie man ein erstes Date organisiert',
            type: 'link',
            url: 'https://www.parship.de/ratgeber/verabreden/erstes-date/',
          },
        },
      ],
    },
    {
      title: {
        fr: "Lire le livre sur les 5 languages de l'amour de Gary Chapman",
        en: 'Read the book on the 5 love languages by Gary Chapman',
        de: 'Das Buch über die 5 Sprachen der Liebe von Gary Chapman lesen',
      },
      budget: 2,
      time: 3,
      image: {
        type: 'unsplash',
        url: 'photo-1497633762265-9d179a990aa6',
      },
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: false,
        word: false,
        stress: false,
        energy: false,
        connection: false,
        eros: false,
        ludus: false,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: true,
        number: false,
        partner_pleasure: false,
        my_pleasure: true,
        duree: false,
        formation: true,
      },
      actions: [
        {
          fr: {
            title: "Les cinq langages de l'amour en résumé",
            type: 'link',
            url: 'https://enduelouenduo.com/les-5-langages-de-lamour-resume/',
          },
          en: {
            title: 'Discover the 5 Love Languages',
            type: 'link',
            url: 'https://5lovelanguages.com/',
          },
          de: {
            title: 'Entdecken Sie die Bücher',
            type: 'link',
            url: 'https://www.5lovelanguages.com/',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Lire le livre *Les femmes viennent de Vénus, les hommes viennent de Mars* de John Gray',
        en: 'Read the book *Men Are from Mars, Women Are from Venus* by John Gray',
        de: 'Das Buch *Frauen kommen von der Venus, Männer kommen vom Mars* von John Gray lesen',
      },
      budget: 2,
      time: 3,
      image: {
        type: 'unsplash',
        url: 'photo-1474366521946-c3d4b507abf2',
      },
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: false,
        word: false,
        stress: false,
        energy: false,
        connection: false,
        eros: false,
        ludus: false,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: true,
        number: false,
        partner_pleasure: false,
        my_pleasure: true,
        duree: false,
        formation: true,
      },
      actions: [
        {
          fr: {
            title: 'Le livre',
            type: 'link',
            url: 'https://www.amazon.fr/hommes-viennent-femmes-Mod%C3%A8le-al%C3%A9atoire/dp/2290029122',
          },
          en: {
            title: 'The book',
            type: 'link',
            url: 'https://www.amazon.com/Men-Mars-Women-Venus-Understanding/dp/0060574216',
          },
          de: {
            title: 'Das Buch',
            type: 'link',
            url: 'https://www.amazon.de/M%C3%A4nner-kommen-Mars-Frauen-Venus/dp/3442166718',
          },
        },
      ],
    },
    {
      title: {
        fr: "Lire le livre sur l'intelligence érotique d'Esther Perel",
        en: 'Read the book *Mating in Captivity: Unlocking Erotic Intelligence* by Esther Perel',
        de: 'Das Buch über die erotische Intelligenz von Esther Perel lesen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1492052722242-2554d0e99e3a',
      },
      budget: 2,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: false,
        word: false,
        stress: false,
        energy: false,
        connection: false,
        eros: false,
        ludus: false,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: true,
        number: false,
        partner_pleasure: false,
        my_pleasure: true,
        duree: false,
        formation: true,
      },
      actions: [
        {
          fr: {
            title: 'Le livre',
            type: 'link',
            url: 'https://www.amazon.fr/Lintelligence-%C3%A9rotique-Esther-PEREL/dp/2266181742?crid=239I0OOQJBYXO&keywords=l%27intelligence+%C3%A9rotique+esther+perel&qid=1703068621&sprefix=l%27intelligence+%C3%A9rotique,aps,73&sr=8-1&linkCode=sl1&tag=zenlove0f-21&linkId=34881466406d94909d2c6bb2ce7905b0&language=fr_FR&ref_=as_li_ss_tl',
          },
          en: {
            title: 'The book',
            type: 'link',
            url: 'https://www.amazon.com/Mating-Captivity-Unlocking-Erotic-Intelligence-ebook/dp/B0071M88DQ',
          },
          de: {
            title: 'Das Buch',
            type: 'link',
            url: 'https://www.amazon.de/Die-erotische-Intelligenz-Esther-Perel/dp/3442166718',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Faire la vaisselle',
        en: 'Do the dishes',
        de: 'Abwaschen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1517241165176-52610c81d064',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: false,
        eros: false,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: true,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Faire la lessive',
        en: 'Do the laundry',
        de: 'Wäsche waschen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1610557892470-55d9e80c0bce',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: false,
        eros: false,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: true,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: "Dire ce que vous aimez chez l'autre",
        en: 'Say what you like about your partner',
        de: 'Sagen Sie, was Ihnen an Ihrem Partner gefällt',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1504122398460-c635d6377010',
      },
      budget: 0,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: false,
        word: true,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: '20 façons de montrer son amour à son partenaire',
            type: 'link',
            url: 'https://www.coupdepouce.com/vie-perso/couple/article/facons-de-montrer-son-amour-a-son-partenaire#:~:text=Lui%20%C3%A9crire%20une%20lettre%2C%20un,d%C3%A9marriez%20votre%20journ%C3%A9e%20de%20travail.',
          },
          en: {
            title: 'Expressing Love Beyond Words',
            type: 'link',
            url: 'https://extension.usu.edu/hru/blog/10-ways-to-express-your-love-to-your-significant-other#:~:text=Plan%20a%20surprise%20date%2C%20give,and%20appreciation%20for%20your%20partner.&text=Expressing%20love%20through%20verbal%20affirmations%20can%20be%20incredibly%20powerful.',
          },
          de: {
            title: 'Liebesbeweise für Ihn',
            type: 'link',
            url: 'https://lemonswan.de/ratgeber/lemonswan-tipps/liebesbeweise-fuer-ihn',
          },
        },
      ],
    },
    {
      title: {
        fr: "Dire la qualité que vous préférez à l'autre",
        en: 'Tell your partner the quality you appreciate most',
        de: 'Sagen Sie die Qualität, die Sie an Ihrem Partner am meisten schätzen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1517867065801-e20f409696b0',
      },
      budget: 0,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: false,
        word: true,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: '11 qualités que les meilleures relations ont en commun',
            type: 'link',
            url: 'https://www.huffpost.com/archive/qc/entry/11-qualites-que-les-meilleures-relations-ont-en-commun_n_13956242',
          },
          en: {
            title: 'The 5 elements of a quality relationship',
            type: 'link',
            url: 'https://www.parentsvictoria.asn.au/issues/5-elements-quality-relationship/',
          },
          de: {
            title: 'Was macht eine gute Beziehung aus?',
            type: 'link',
            url: 'https://chrisbloom.de/blog/was-macht-eine-gute-beziehung-aus/',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Dire à votre partenaire comme il est précieux à vos yeux',
        en: 'Tell your partner how precious they are to you',
        de: 'Sagen Sie Ihrem Partner, wie wertvoll er für Sie ist',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1518636693090-8407756ab88b',
      },
      budget: 0,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: false,
        word: true,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: "Dites à votre partenaire comme vous l'admirez et êtes fière ",
        en: 'Tell your partner how proud you are of them',
        de: 'Sagen Sie Ihrem Partner, wie stolz Sie auf ihn sind',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1516462919870-8bcf749b0135',
      },
      budget: 0,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: false,
        word: true,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Dites à votre partenaire que vous le trouvez beau / belle',
        en: 'Tell your partner that you find them really attractive',
        de: 'Sagen Sie Ihrem Partner, dass Sie ihn/sie sehr attraktiv finden',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1572495754162-78a92305ea6a',
      },
      budget: 0,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: false,
        word: true,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Aller checher des croissants frais à la boulangerie',
        en: 'Go get fresh croissants from the bakery',
        de: 'Frische Croissants aus der Bäckerei holen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1677740929724-1aa2dc110721',
      },
      budget: 2,
      time: 1,
      categories: {
        touch: false,
        service: true,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: true,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Construisez une cabane dans votre salon',
        en: 'Build a hut in your living room',
        de: 'Bauen Sie eine Hütte in Ihrem Wohnzimmer',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1621872112223-167c62e0782a',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Faites une bataille de coussin',
        en: 'Have a pillow fight',
        de: 'Machen Sie eine Kissenschlacht',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1513807016779-d51c0c026263',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Rejouer le moment de la première rencontre',
        en: 'Replay the moment of your first meeting',
        de: 'Wiederholen Sie den Moment des ersten Treffens',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1642721366091-31bc60ada871',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Organiser un blind test de musique',
        en: 'Organize a music blind test',
        de: 'Organisieren Sie einen Musik-Blindtest',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1470225620780-dba8ba36b745',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: "Test à l'aveugle musique classique",
            type: 'link',
            url: 'https://www.youtube.com/watch?v=LVZYU_4VlHE',
          },
          en: {
            title: 'Classical blind test',
            type: 'link',
            url: 'https://www.youtube.com/watch?v=LVZYU_4VlHE',
          },
          de: {
            title: 'Blindtest klassische Musik',
            type: 'link',
            url: 'https://www.youtube.com/watch?v=LVZYU_4VlHE',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Aller à une dégustation',
        en: 'Go to a tasting',
        de: 'Gehen Sie zu einer Verkostung',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1663053438702-b2bd1122a03f',
      },
      budget: 2,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Faites une activité insolite dans la région',
        en: 'Do an unusual activity in the region',
        de: 'Machen Sie eine ungewöhnliche Aktivität in der Region',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1500679215756-b09636d3786e',
      },
      budget: 3,
      time: 4,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Envoyer un SMS coquin à votre partenaire',
        en: 'Send a sexy text message to your partner',
        de: 'Senden Sie eine sexy SMS an Ihren Partner',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1611746869696-d09bce200020',
      },
      budget: 0,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: false,
        word: true,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: true,
        number: true,
        partner_pleasure: true,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Comment écrire des sextos ?',
            type: 'link',
            url: 'https://www.santemagazine.fr/psycho-sexo/le-blog-sexo-de-daisy-et-marine/maitriser-lart-du-sexto-174232',
          },
          en: {
            title: '48 Sexting Ideas For When You’ve Got Writer’s Block',
            type: 'link',
            url: 'https://www.refinery29.com/en-us/sexting-examples-how-to-sext',
          },
          de: {
            title:
              '48 Sexting-Beispiele: heiße Tipps, Ideen und Vorlagen für Paare',
            type: 'link',
            url: 'https://www.andreas-lorenz.com/whatsapp-tipps/sexting-beispiele-vorlagen-ideen-sms-nachrichten/',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Cacher un petit cadeau dans les poches de votre partenaire',
        en: 'Hide a little gift in the pockets of your partner',
        de: 'Verstecken Sie ein kleines Geschenk in der Tasche Ihres Partners',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1545785028-23ee5937cf69',
      },
      budget: 1,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: true,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Commander un repas en ligne',
        en: 'Order food online',
        de: 'Bestellen Sie eine Mahlzeit online',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1521305916504-4a1121188589',
      },
      budget: 2,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: true,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Faire un concours de sudoku',
        en: 'Hold a sudoku competition',
        de: 'Einen Sudoku-Wettbewerb veranstalten',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1640537702474-3e503c21eefc',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: false,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Sudoku à imprimer',
            type: 'link',
            url: 'https://1sudoku.com/fr/imprimer-sudoku',
          },
          en: {
            title: 'Sudoku to print',
            type: 'link',
            url: 'https://sudoku.cba.si/en/',
          },
          de: {
            title: 'Sudoku zum Ausdrucken',
            type: 'link',
            url: 'https://sudoku.cba.si/de/',
          },
        },
      ],
    },
    {
      title: {
        fr: "Goûter ensemble quelque chose que vous n'avez jamais essayé",
        en: 'Taste something together that you have never tried',
        de: 'Probieren Sie gemeinsam etwas, das Sie noch nie versucht haben',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1513618827672-0d7c5ad591b1',
      },
      budget: 1,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Aller vous promener ensemble sans but',
        en: 'Go for a walk together without a goal',
        de: 'Gehen Sie zusammen spazieren ohne Ziel',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1450022214806-a468e98a9106',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: "Faites l'amour dans un endroit insolite",
        en: 'Make love in an unusual place',
        de: 'Machen Sie Liebe an einem ungewöhnlichen Ort',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1581289064309-da58a92766ff',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: true,
        number: true,
        partner_pleasure: true,
        my_pleasure: true,
        duree: true,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: "Les endroits insolites pour faire l'amour",
            type: 'link',
            url: 'https://www.femina.fr/article/15-lieux-insolites-pour-faire-l-amour',
          },
          en: {
            title: 'Unusual places to make love',
            type: 'link',
            url: 'https://discover.hubpages.com/health/150-Best-Places-Ever-to-Have-Sex',
          },
          de: {
            title: 'Ungewöhnliche Orte, um Liebe zu machen',
            type: 'link',
            url: 'https://www.cosmopolitan.de/sex-bucket-list-55-orte-an-denen-du-sex-haben-musst-82885.html',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Faites un câlin non sexuel à votre partenaire',
        en: 'Give your partner a non-sexual hug',
        de: 'Geben Sie Ihrem Partner eine nicht-sexuelle Umarmung',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1499568509606-4f9b771232ed',
      },
      budget: 0,
      time: 0,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: true,
        number: false,
        partner_pleasure: true,
        my_pleasure: true,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Faites une commande surprise en ligne pour votre partenaire',
        en: 'Make a surprise order for your partner online',
        de: 'Machen Sie eine Überraschungsbestellung für Ihren Partner online',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1686632800715-b705ba1b0eb6',
      },
      budget: 2,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Demander à votre partenaire sa plus grosse peur',
        en: 'Ask your partner what their biggest fear is',
        de: 'Fragen Sie Ihren Partner, was seine größte Angst ist',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1518343265568-51eec52d40da',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: true,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Demander à votre partenaire quels sont ses rêves',
        en: 'Ask your partner what their dreams are',
        de: 'Fragen Sie Ihren Partner, was seine Träume sind',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1534447677768-be436bb09401',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: true,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Comment parler des rêves avec votre partenaire',
            type: 'link',
            url: 'https://www.love-intelligence.fr/problemes-couple/nos-reves-confrontes-au-couple',
          },
          en: {
            title: 'Epic Ways to Support Each Other’s Dreams',
            type: 'link',
            url: 'https://www.greatlakescounselinggroup.com/post/3-epic-ways-to-support-each-other-s-dreams',
          },
          de: {
            title: 'Warum Sie als Paar über Ihre Lebensträume reden sollten',
            type: 'link',
            url: 'https://www.welt.de/vermischtes/plus215500454/Beziehungstipps-Warum-Sie-als-Paar-ueber-Ihre-Lebenstraeume-reden-sollten.html',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Regarder votre partenaire dans les yeux pendant 1 minutes sans rire',
        en: 'Look your partner in the eye for more than one minute without laughing',
        de: 'Schauen Sie Ihrem Partner mehr als eine Minute lang in die Augen, ohne zu lachen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1516220362602-dba5272034e7',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Faites une bataille de pouce',
        en: 'Have a thumb fight',
        de: 'Machen Sie einen Daumenkampf',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1580893211123-627e0262be3a',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Comment faire une bataille de pouce',
            type: 'link',
            url: 'https://fr.wikipedia.org/wiki/Bataille_de_pouces',
          },
          en: {
            title: 'How to have a thumb fight',
            type: 'link',
            url: 'https://en.wikipedia.org/wiki/Thumb_war',
          },
          de: {
            title: 'Wie man einen Daumenkampf macht',
            type: 'link',
            url: 'https://www.youngstarswiki.org/de/wiki/art/daumenwrestling',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Buvez un verre au jardin ou sur votre balcon',
        en: 'Have a drink together in the garden or on your balcony',
        de: 'Trinken Sie gemeinsam etwas auf Ihrem Balkon oder im Garten',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1667992403195-d2241a40ca2d',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: true,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Parlez de vos vacances de rêves',
        en: 'Talk about your dream holiday',
        de: 'Sprechen Sie über Ihren Traumurlaub',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1538475711279-0373b6bc754e',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: true,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Parlez de vos désirs futurs ensemble',
        en: 'Talk about your future plans together',
        de: 'Sprechen Sie über Ihre zukünftigen Pläne zusammen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1525876183281-0d0d9308010d',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: true,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: '5 sujets à aborder',
            type: 'link',
            url: 'https://www.mariages.net/articles/5-sujets-de-conversation-a-aborder-avant-de-vous-marier--c5800',
          },
          en: {
            title: 'How to Talk to Your Partner About Your Future',
            type: 'link',
            url: 'https://www.gottman.com/blog/how-to-talk-to-your-partner-about-your-future/',
          },
          de: {
            title:
              'Warum es für Paare so wichtig ist, über ihre Zukunft zu sprechen',
            type: 'link',
            url: 'https://www.stern.de/gesundheit/psychologie/liebe-allein-reicht-nicht-aus--paare-muessen-ueber-die-zukunft-sprechen-34603346.html',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Faites un concours de sculpture dans une pomme',
        en: 'Make an apple sculpture competition',
        de: 'Machen Sie einen Apfel-Skulptur-Wettbewerb',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1600626333392-59a20e646d97',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Louer un vélo deux places (tandems)',
        en: 'Rent a two-seater bike (tandem)',
        de: 'Mieten Sie ein Zweiplatz-Fahrrad',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1704902750130-6a01bad0f1a8',
      },
      budget: 2,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Inventer un jeu ensemble',
        en: 'Invent a game together',
        de: 'Erfinden Sie gemeinsam ein Spiel',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1594652634010-275456c808d0',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Faites un massage des pieds à votre partenaire',
        en: 'Give your partner a foot massage',
        de: 'Machen Sie Ihrem Partner eine Fußmassage',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1507070491081-c86dc15d6e12',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: false,
        number: true,
        partner_pleasure: true,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Comment faire un bon massage des pieds',
            type: 'link',
            url: 'https://fr.wikihow.com/faire-un-massage-du-pied',
          },
          en: {
            title: 'How to make a good foot massage',
            type: 'link',
            url: 'https://www.wikihow.com/Give-a-Foot-Massage',
          },
          de: {
            title: 'Wie man eine gute Fußmassage macht',
            type: 'link',
            url: 'https://www.nivea.ch/de-ch/beratung/lifestyle/anleitung-zur-fussmassage-einfach-entspannend',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Faites un massage des mains à votre partenaire',
        en: 'Give your partner a hand massage',
        de: 'Machen Sie Ihrem Partner eine Handmassage',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1505455184862-554165e5f6ba',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: false,
        number: true,
        partner_pleasure: true,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Comment faire un massage des mains',
            type: 'link',
            url: 'https://www.secretsdemiel.com/blog/reflexologie-auto-massage-des-mains-a-realiser-soi-meme-detendre-et-rebooster-le-corps/',
          },
          en: {
            title: 'How to make a good hand massage',
            type: 'link',
            url: 'https://www.wikihow.com/Massage-Someone%27s-Hand',
          },
          de: {
            title: 'Wie man eine gute Handmassage macht',
            type: 'link',
            url: 'https://www.eucerin.de/beratung/hautbehandlung/handmassage',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Faites un massage de la tête à votre partenaire',
        en: 'Give your partner a head massage',
        de: 'Machen Sie Ihrem Partner eine Kopfmassage',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1542848285-4777eb2a621e',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: false,
        number: true,
        partner_pleasure: true,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Comment faire un massage de la tête',
            type: 'link',
            url: 'https://www.nivea.ch/fr-ch/conseils/idees-pratiques/massage-de-la-tete-programme-bien-etre-explique',
          },
          en: {
            title: 'How to make a good head massage',
            type: 'link',
            url: 'https://www.wikihow.com/Give-a-Head-Massage',
          },
          de: {
            title: 'Wie man eine gute Kopfmassage macht',
            type: 'link',
            url: 'https://www.nivea.ch/de-ch/beratung/lifestyle/kopfmassage-anleitung-fuer-das-wohlfuehlprogramm',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Aller à un bel endroit',
        en: 'Go to a beautiful place',
        de: 'Gehen Sie an einen schönen Ort',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1500167176026-330fee16a975',
      },
      budget: 0,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Faites une activité interdite ensemble',
        en: 'Do a forbidden activity together',
        de: 'Machen Sie gemeinsam eine verbotene Aktivität',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1544713061-21cd773e9130',
      },
      budget: 0,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: "Aller dans votre parc d'attraction local",
        en: 'Go to your local amusement park',
        de: 'Gehen Sie in Ihren örtlichen Vergnügungspark',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1502136969935-8d8eef54d77b',
      },
      budget: 3,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Hypersensibles et hyperamoureux : émotions puissance 10',
            type: 'link',
            url: 'https://madame.lefigaro.fr/bien-etre/hypersensible-couple-hyperamoureux-des-emotions-puissance-10-010221-194784',
          },
          en: {
            title: 'Love (and marriage) is like an Amusement Park',
            type: 'link',
            url: 'https://relativeevolutions.com/2018/10/love-is-an-amusement-park/',
          },
          de: {
            title: 'Liebe (und Ehe) ist wie ein Vergnügungspark',
            type: 'link',
            url: 'https://relativeevolutions-com.translate.goog/2018/10/love-is-an-amusement-park/?_x_tr_sl=en&_x_tr_tl=de&_x_tr_hl=en&_x_tr_pto=wapp',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Laisser votre partenaire choisir une activité de son choix',
        en: 'Let your partner choose an activity of their choice',
        de: 'Lassen Sie Ihren Partner eine Aktivität seiner Wahl wählen',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1466495227171-d05d7e3ac2b3',
      },
      budget: 2,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: true,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: '120 idées d’activités à faire en couple',
            type: 'link',
            url: 'https://becometheartist.com/120-idees-activites-a-faire-couple/',
          },
          en: {
            title: '50 Fun Things for Couples to Do for Quality Time Together',
            type: 'link',
            url: 'https://www.theknot.com/content/couple-activities',
          },
          de: {
            title: '80 Ideen : Unternehmungen für Paare',
            type: 'link',
            url: 'https://www.stuttgarter-zeitung.de/inhalt.unternehmungen-fuer-paare-mhsd.1f0094bc-99a9-40f5-bd65-b19be03563f3.html',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Accompagner votre partenaire pour faire sa passion',
        en: 'Go with your partner to make his / her passion',
        de: 'Begleiten Sie Ihren Partner, um seine Leidenschaft auszuüben',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1518611012118-696072aa579a',
      },
      budget: 1,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: true,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Vivre avec la passion du conjoint',
            type: 'link',
            url: 'https://www.la-croix.com/Famille/Parents-et-enfants/Vivre-passion-conjoint-2019-04-02-1201013016',
          },
          en: {
            title: 'Share in your partner’s passions!',
            type: 'link',
            url: 'https://marriedandcool.wordpress.com/2014/03/21/share-in-your-partners-passions/',
          },
          de: {
            title: 'Warum es wichtig ist, gemeinsame Interessen zu haben?',
            type: 'link',
            url: 'https://www.happiness-for-senses.com/warum-es-wichtig-ist-gemeinsame-interessen-zu-haben/',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Donner carte blanche à votre partenaire pour un achat plaisir',
        en: 'Let your partned do a fun purchase',
        de: 'Geben Sie Ihrem Partner freie Hand für einen Lustkauf',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1513885045260-6b3086b24c17',
      },
      budget: 3,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: true,
        word: false,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: true,
        decision: true,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Top 10 des achats qui nous procurent du plaisir',
            type: 'link',
            url: 'https://www.topito.com/top-achats-plaisir-etonnant',
          },
          en: {
            title: '10 Things To Buy To Be Happier',
            type: 'link',
            url: 'https://www.newtraderu.com/2024/07/03/10-things-to-buy-to-be-happier-according-to-science/',
          },
          de: {
            title:
              'Glück kann man kaufen: Hier sind sieben Dinge, die dabei helfen',
            type: 'link',
            url: 'https://www.finanzen100.de/finanznachrichten/wirtschaft/wissenschaftlich-bewiesen-glueck-kann-man-kaufen-hier-sind-sieben-dinge-die-dabei-helfen-schon-fuer-5-euro_H699276725_441335/',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Aller chercher votre partenaire à son travail',
        en: 'Go pick up your partner from work',
        de: 'Holen Sie Ihren Partner von der Arbeit ab',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1487017159836-4e23ece2e4cf',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Donner carte blanche à votre partenaire pour faire un changement chez vous',
        en: 'Allow your partner to change something about you',
        de: 'Geben Sie Ihrem Partner freie Hand, um etwas an Ihnen zu ändern',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1477414348463-c0eb7f1359b6',
      },
      budget: 3,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: true,
        word: false,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: true,
        decision: true,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Pouvons-nous réellement changer notre conjoint(e)?',
            type: 'link',
            url: 'https://www.noovomoi.ca/vivre/couple/article.pouvons-nous-reellement-changer-notre-conjoint-e.1.2920540.html',
          },
          en: {
            title: 'Why It’s Okay to Ask Your Partner to Change For You',
            type: 'link',
            url: 'https://samholstein.com/why-its-okay-to-ask-your-partner-to-change-for-you-sometimes/',
          },
          de: {
            title: 'Experte erklärt: Deshalb muss man seinen Partner ändern',
            type: 'link',
            url: 'https://www.brigitte.de/liebe/beziehung/warum-man-den-partner-aendern-muss-10944434.html',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Offrir le coiffeur à votre partenaire',
        en: 'Treat your partner to a haircut',
        de: 'Bieten Sie Ihrem Partner den Friseur an',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1620331315356-06e90155dcaa',
      },
      budget: 3,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: true,
        word: false,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: true,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Comment choisir un bon coiffeur',
            type: 'link',
            url: 'https://hygiene-plus.com/2014/08/conseils-bien-choisir-coiffeur-salon-coiffure/',
          },
          en: {
            title: 'How to choose a good hairdresser',
            type: 'link',
            url: 'https://canonburyhair.co.uk/top-tips-for-choosing-the-right-hairdresser/',
          },
          de: {
            title: 'Wie man einen guten Friseur auswählt',
            type: 'link',
            url: 'https://www.friseurkonstanz.de/woran-erkenne-ich-einen-guten-friseur',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Construire une tour avec des pâtes',
        en: 'Build a tower with pasta',
        de: 'Bauen Sie einen Turm mit Nudeln',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1447279506476-3faec8071eee',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: false,
        eros: false,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: true,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Comment construire une tour avec des pâtes',
            type: 'link',
            url: 'https://cursus.edu/fr/12250/monter-une-tour-en-spaghetti-pour-souder-une-equipe',
          },
          en: {
            title: 'How to build a tower with pasta',
            type: 'link',
            url: 'https://www.wikihow.com/Build-a-Spaghetti-Tower',
          },
          de: {
            title: 'Wie man einen Turm mit Nudeln baut',
            type: 'link',
            url: 'https://entrepreneurship-toolbox.com/toolbox/spaghetti-turm/',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Discuter philosophie',
        en: 'Discuss philosophy',
        de: 'Besprechen Sie Philosophie',
      },
      image: {
        type: 'unsplash',
        url: 'photo-1564957352881-134f7a0713d5',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: false,
        eros: false,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: true,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Sujets de philosophie faciles',
            type: 'link',
            url: 'https://www.philofacile.com/',
          },
          en: {
            title: '100 Philosophy Topics',
            type: 'link',
            url: 'https://edusson.com/blog/philosophy-essay-topics',
          },
          de: {
            title: 'Große philosophische Fragen',
            type: 'link',
            url: 'https://www.philosophie.ch/grosse-fragen',
          },
        },
      ],
    },
  ];
}
