import { Component, OnInit, HostListener, Input } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ServicesService } from '@app/services/services.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.page.html',
  styleUrls: ['./contact.page.scss'],
})
export class ContactPage implements OnInit {
  validations_form_email: FormGroup;
  randomImage: string = '';

  constructor(
    public services: ServicesService,
    private formBuilder: FormBuilder
  ) {
    this.randomImage = this.services.utilsService.randomImageCategories();

    this.validations_form_email = this.formBuilder.group({
      email: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(255),
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ])
      ),
      text: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.maxLength(10000)])
      ),
    });
  }

  ngOnInit() {}

  ionViewWillEnter() {
    const modalState = {
      modal: true,
      desc: 'fake state for our modal',
    };
    history.pushState(modalState, null!);
  }

  ionViewWillLeave() {
    if (window.history.state.modal) {
      window.history.back();
    }
  }

  send() {
    if (!this.validations_form_email.valid) {
      this.validations_form_email.markAllAsTouched();
      return;
    }

    let email = this.validations_form_email.controls['email'].value;

    email = email.toLowerCase();
    email = email.replace(' ', '');

    this.services.utilsService.presentLoading();

    let data = JSON.stringify({
      unique_identifier: this.services.sessionService.unique_identifier,
      email: email,
      message: this.validations_form_email.controls['text'].value,
    });
    this.services.apiService
      .post('contact', data)
      .subscribe({
        next: (data: any) => {
          if (data.status_code == 200) {
            this.services.utilsService.presentToast('contact.successful');
            this.dismiss();
          } else {
          }
        },
        error: (e) => {
          console.error(e);
        },
        complete: () => {},
      })
      .add(() => {
        this.services.utilsService.dismissLoading();
      });
  }

  @HostListener('document:keypress', ['$event'])
  enterKey(event) {
    if (event.key == 'Enter') {
      this.send();
    }
  }

  @HostListener('window:popstate', ['$event'])
  async dismiss(role: string = 'dismiss') {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    const modal = await this.services.modalController.getTop();
    if (modal) {
      this.services.modalController.dismiss(null, role);
      return;
    }
  }
}
