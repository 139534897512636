<ion-content>
  <div class="background-image">
    <ion-img src="{{randomImage}}"></ion-img>
    <div class="gradient"></div>
    <div class="gradient2"></div>
  </div>

  <ion-fab vertical="top" horizontal="end" slot="fixed">
    <ion-fab-button color="white" size="small" (click)="dismiss()">
      <ion-icon name="close"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <div class="container">
    <div class="middle allWidth flex-col-vertical-center">
      <ng-container *ngIf="image_or_icon != null">
        <ion-icon
          *ngIf="!image_or_icon.includes('/')"
          [name]="image_or_icon"
          size="large"
        ></ion-icon>
        <ion-img
          class="illustration"
          *ngIf="image_or_icon.includes('/')"
          [src]="image_or_icon"
        ></ion-img>
      </ng-container>

      <ion-row class="title-container flex-col-center">
        <div class="avatar float">
          <img
            alt="Giorgio character"
            src="assets/images/characters/specific/{{emotion_giorgio}}.png"
          />
        </div>
        <h1 class="title-modal">{{title | translate}}</h1>
      </ion-row>

      <app-divider></app-divider>
      <div class="allWidth ion-margin-top">
        <app-text-html
          [html]="content | translate"
          [limitHeight]="false"
          [canOpenMore]="false"
          alignment="justify"
        ></app-text-html>
      </div>
    </div>
  </div>
</ion-content>
