<ion-content [fullscreen]="true">
  <div class="background-image">
    <ion-img src="{{randomImage}}"></ion-img>
    <div class="gradient"></div>
    <div class="gradient2"></div>
  </div>

  <ion-fab vertical="top" horizontal="end" slot="fixed">
    <ion-fab-button color="white" size="small" (click)="dismiss()">
      <ion-icon name="close"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <div class="allWidth">
    <h1 class="ion-text-center ion-padding-end title-modal" style="">
      {{'faq.title'|translate}}
    </h1>
    <app-divider></app-divider>
    <ion-list>
      <ng-container *ngFor="let cat of list_faq">
        <ng-container>
          <ion-list-header>
            <ion-label color="white"> {{cat.title | translate}} </ion-label>
          </ion-list-header>
          <ng-container *ngFor="let item of cat.items; let last = last;">
            <ion-item
              lines="{{last||item.opened?'none':'full'}}"
              button
              detail
              detailIcon="{{item.opened?'remove-circle':'add-circle'}}"
              (click)="openItem(item)"
            >
              <ion-label>{{item.title | translate}}</ion-label>
            </ion-item>
            <div
              class="allWidth accordeonClosed"
              [class.accordeonOpened]="item.opened"
            >
              <div class="allWidth ion-padding">
                <app-text-html
                  alignment="left"
                  [forceHeight]="false"
                  [maxHeight]="150"
                  [html]="item.text|translate"
                  [canOpenMore]="false"
                  [limitHeight]="false"
                ></app-text-html>
                <div
                  *ngIf="item.buttons && item.buttons.length > 0"
                  class="flex-col-vertical-center ion-margin-top"
                >
                  <ng-container *ngFor="let button of item.buttons;">
                    <ion-button
                      color="primary-gradient"
                      shape="round"
                      (click)="openButton(button)"
                    >
                      <ion-icon
                        slot="end"
                        *ngIf="button.name == null && button.src == null"
                        name="chevron-forward"
                      ></ion-icon>
                      <ion-icon
                        slot="end"
                        *ngIf="button.name"
                        name="{{button.name}}"
                      ></ion-icon>
                      <ion-icon
                        slot="end"
                        *ngIf="button.src"
                        src="assets/icons/noColor/{{button.src}}"
                      ></ion-icon>
                      <ion-label>{{button.title | translate}}</ion-label>
                    </ion-button>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ion-list>
    <ion-text color="primary">
      <h3
        class="ion-text-center ion-margin-start ion-margin-end ion-padding-top"
      >
        {{'faq.anotherQuestion' | translate}}
      </h3>
    </ion-text>
    <p class="ion-text-center ion-margin">
      {{'faq.contactOurSupport' | translate}}
    </p>
    <div class="allWidth flex-col-center ion-margin-bottom">
      <ion-button color="primary-gradient" shape="round" (click)="contact()">
        <ion-icon slot="end" name="chatbubble-ellipses"></ion-icon>
        <ion-label>{{'faq.contactSupport' | translate}}</ion-label>
      </ion-button>
    </div>
  </div>
</ion-content>
