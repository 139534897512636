import { Injectable } from '@angular/core';
import { Debug } from '@app/class/debug';
import { Storage } from '@ionic/storage-angular';
import { Observable, of, from, Observer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  public ready: boolean = false;
  object: any = {};
  permanent_object: any = {};
  public storage!: any;
  constructor(private _storage: Storage) {
    this.initStorage();
  }

  async initStorage() {
    const storage = await this._storage.create();
    this.storage = storage;

    this.loadStorage();
  }

  async loadStorage() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);

    this.storage.get('love-flame').then((data: any) => {
      if (data) {
        try {
          this.object = JSON.parse(data);
        } catch (exception) {
          console.log(data);
          console.log('PROBLEM DATA');
        }
      }
      if (this.object == null) {
        this.object = {};
      }
      this.ready = true;
    });
  }

  isReady(): Observable<any> {
    return Observable.create((observer: Observer<any>) => {
      if (this.ready) {
        observer.next(true);
        observer.complete();
      } else {
        let interval = setInterval(() => {
          if (this.ready) {
            clearInterval(interval);
            observer.next(true);
            observer.complete();
          }
        }, 200);
      }
    });
  }

  set(key: string, data: any) {
    this.object[key] = data;
    this.save();
  }

  get(key: string, defaut = null) {
    if (this.object[key] != null) {
      return this.object[key];
    } else {
      return defaut;
    }
  }

  check(key: string) {
    return this.object[key] != null;
  }

  delete(key: string) {
    this.object[key] = null;
    this.save();
  }

  logContent() {
    Debug.log(JSON.stringify(this.object));
  }

  deleteAll() {
    this.object = {};
    console.log('Deleted all storage infos');
    this.save();
  }

  save() {
    let data = JSON.stringify(this.object);

    this.storage.set('love-flame', data);
  }
}
