import { Injectable } from '@angular/core';
import { catchError, Observable, retryWhen } from 'rxjs';
import { environment } from '@env/environment';

import {
  HttpHeaders,
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { Debug } from '@app/class/debug';
import { genericRetryStrategy } from '@app/class/rxjs-utils';
import { UtilsService } from './utils.service';
import { ServicesService } from './services.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  token: string = null;

  server_url = 'https://api.loveflame.app/api/';

  constructor(
    public httpClient: HttpClient,
    public utilsService: UtilsService,
    public services: ServicesService
  ) {
    if (this.utilsService.getDebug('localhost_server')) {
      this.server_url = 'http://localhost/api/';
    } else {
      this.server_url = environment.server_url;
    }
  }

  getAssetsPath(path: string): string {
    return this.server_url.replace('api/', '') + path;
  }

  headerGenerator() {
    let httpOptions = null;
    if (this.token != null) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.token,
        }),
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      };
    }
    return httpOptions;
  }

  headerGeneratorFormData() {
    var httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.token,
      }),
    };

    return httpOptions;
  }

  setToken(token) {
    this.token = token;
    //console.log(this.token);
  }

  log(title: string, text: string) {
    let data = JSON.stringify({
      unique_identifier: this.services.sessionService.unique_identifier,
      title: title,
      text: text,
    });

    this.post('email/log', data).subscribe((data) => {
      console.log('Log');
    });
  }

  get(path: string) {
    return this.httpClient
      .get<any>(this.server_url + path, this.headerGenerator())
      .pipe(
        retryWhen(
          genericRetryStrategy({
            scalingDuration: 1000,
            excludedStatusCodes: [404, 403],
          })
        )
      );
  }

  post(path: string, object: any) {
    return this.httpClient
      .post<any>(this.server_url + path, object, this.headerGenerator())
      .pipe(
        retryWhen(
          genericRetryStrategy({
            scalingDuration: 1000,
            excludedStatusCodes: [404, 403],
          })
        )
      );
  }

  put(path: string, object: any) {
    return this.httpClient
      .put<any>(this.server_url + path, object, this.headerGenerator())
      .pipe(
        retryWhen(
          genericRetryStrategy({
            scalingDuration: 1000,
            excludedStatusCodes: [404, 403],
          })
        )
      );
  }

  delete(path: string) {
    return this.httpClient
      .delete<any>(this.server_url + path, this.headerGenerator())
      .pipe(
        retryWhen(
          genericRetryStrategy({
            scalingDuration: 1000,
            excludedStatusCodes: [404, 403],
          })
        )
      );
  }
}
