import { Injectable } from '@angular/core';
import { ServicesService } from '@app/services/services.service';

@Injectable({
  providedIn: 'root',
})
export class BrowserService {
  public window: any = null;

  constructor(public services: ServicesService) {}

  public open(url = null) {
    return new Promise((resolve, reject) => {
      if (url == null) {
        url = '';
      }
      let loading = this.services.utilsService.getInstantTraduction('loading');
      this.window = window.open(url, '_blank');
      if (this.window) {
        this.window.document.write(
          `<!DOCTYPE html><html lang="en"><head><meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><title>${loading}...</title></head><body style=";margin:0px;"><div style="width:100vw;height:100vh;display: flex; justify-content: center; align-items: center;"><h1 style="color:white; font-family: 'Helvetica', 'Arial', sans-serif;">${loading}...</h1></div></body></html>`
        );
        resolve('done');
      } else {
        this.services.utilsService.presentToast(
          this.services.utilsService.getInstantTraduction(
            'errorMessages.popupBlocked'
          )
        );

        reject('error');
      }
      //this.window.focus();

      //this.window.focus();
      //this.window.onbeforeunload = ()=>{ this.window = null; }
    });
  }

  public write(text) {
    if (this.window) {
      this.window.document.write(
        `<!DOCTYPE html><html lang="en"><head><meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><title>${text}...</title></head><body style="margin:0px;"><div style="width:100vw;height:100vh;display: flex; justify-content: center; align-items: center;"><h1 style="color:white; font-family: 'Helvetica', 'Arial', sans-serif;">${text}...</h1></div></body></html>`
      );
    }
  }

  async openWithParams(url, name, params) {
    if (
      this.services.utilsService.isNativeIOS &&
      !url.includes('opensynaps.com') &&
      !url.includes('stripe.com')
    ) {
      url =
        this.services.apiService.server_url +
        'redirect?url=' +
        encodeURIComponent(url);
    }

    window.open(url, name, params);
  }

  public async openUrl(url, simple = false) {
    if (this.window == null || this.window.closed || simple) {
      if (
        this.services.utilsService.isNativeIOS &&
        !url.includes('loveflame.app') &&
        !url.includes('stripe.com')
      ) {
        url =
          this.services.apiService.server_url +
          'redirect?url=' +
          encodeURIComponent(url);
      }
      this.window = window.open(url, '_blank');
    } else {
      this.window.location.href = url;
    }
  }

  public close() {
    if (this.window) {
      this.window.close();
      this.window = null;
    }
  }
}
