<ion-content>
  <div class="background-image">
    <ion-img src="{{randomImage}}"></ion-img>
    <div class="gradient"></div>
    <div class="gradient2"></div>
  </div>

  <ion-fab vertical="top" horizontal="end" slot="fixed">
    <ion-fab-button color="white" size="small" (click)="dismiss()">
      <ion-icon name="close"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <div class="container">
    <div class="middle">
      <h1>{{'login.login' | translate}}</h1>
      <app-divider></app-divider>
      <ion-button
        shape="round"
        size="large"
        style="--background: #db4437"
        (click)="loginSocial('google')"
      >
        <ion-icon name="logo-google" slot="end"></ion-icon>
        <ion-label class="bold">{{'login.google'|translate}}</ion-label>
      </ion-button>

      <ion-button
        shape="round"
        size="large"
        style="--background: #4267b2"
        (click)="loginSocial('facebook')"
      >
        <ion-icon name="logo-facebook" slot="end"></ion-icon>
        <ion-label class="bold">{{'login.facebook'|translate}}</ion-label>
      </ion-button>

      <div class="allWidth flex-col-center ion-margin-top ion-margin-bottom">
        <span style="width: 20px; height: 1px; background-color: white"></span>
        <span style="margin-right: 5px; margin-left: 5px; color: white"
          >{{ "or" | translate }}</span
        >
        <span style="width: 20px; height: 1px; background-color: white"></span>
      </div>

      <form [formGroup]="validations_form_email" *ngIf="stepLogin == 1">
        <div
          *ngIf="validations_form_email.controls['email'].touched && !validations_form_email.controls['email'].valid"
          class="allWidth flex-col-center"
        >
          <ion-icon class="ion-margin-end" name="warning-outline"></ion-icon>
          <span>{{'login.problemEmail' | translate}}</span>
        </div>

        <ion-item lines="none" class="transparent" class="ion-margin-top">
          <ion-icon color="primary" slot="start" name="mail"></ion-icon>
          <ion-input
            required
            formControlName="email"
            inputmode="email"
            type="email"
            placeholder="{{'login.yourEmail' | translate}}"
            autocomplete="on"
            required
          ></ion-input>
        </ion-item>
        <div
          class="allWidth ion-text-center ion-margin-top ion-margin-bottom ion-padding-start ion-padding-end"
        >
          <ion-button
            color="primary-gradient"
            shape="round"
            size="large"
            (click)="askValidationCode()"
          >
            <ion-icon name="chevron-forward" slot="end"></ion-icon>
            {{'login.valid'|translate}}
          </ion-button>
        </div>
      </form>

      <form [formGroup]="validations_form_code" *ngIf="stepLogin == 2">
        <p class="ion-text-center">
          {{'login.codeExplication' | translate:{email:email} }}
        </p>
        <div
          *ngIf="validations_form_code.controls['code'].touched && !validations_form_code.controls['code'].valid"
          class="allWidth flex-col-center"
        >
          <ion-icon class="ion-margin-end" name="warning-outline"></ion-icon>
          <span>{{'login.problemCode' | translate}}</span>
        </div>

        <ion-item lines="none" class="transparent" class="ion-margin-top">
          <ion-icon color="primary" slot="start" name="key"></ion-icon>
          <ion-input
            required
            formControlName="code"
            type="text"
            placeholder="{{'login.yourCode' | translate}}"
            autocomplete="off"
            required
          ></ion-input>
        </ion-item>
        <div
          class="allWidth ion-text-center ion-margin-top ion-margin-bottom ion-padding-start ion-padding-end"
        >
          <ion-button
            color="primary-gradient"
            shape="round"
            size="large"
            (click)="validateCodeOrPassword()"
          >
            <ion-icon name="chevron-forward" slot="end"></ion-icon>
            {{'login.valid'|translate}}
          </ion-button>
        </div>

        <div
          class="allWidth flex-col-center underline hover"
          (click)="stepLogin =1 ;"
        >
          <p>{{'login.back' | translate}}</p>
        </div>
      </form>

      <div
        class="allWidth flex-col-center underline hover"
        (click)="register()"
      >
        <p>{{'login.register' | translate}}</p>
      </div>
    </div>
  </div>
</ion-content>
