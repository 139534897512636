<ion-content>
  <div class="background-image">
    <ion-img src="{{randomImage}}"></ion-img>
    <div class="gradient"></div>
    <div class="gradient2"></div>
  </div>

  <ion-fab vertical="top" horizontal="end" slot="fixed">
    <ion-fab-button color="white" size="small" (click)="dismiss()">
      <ion-icon name="close"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <div class="container">
    <div class="middle">
      <div class="allWidth first-explication flex-col-center">
        <ion-img
          class="img-giorgio float"
          src="assets/images/characters/specific/sorcier.png"
        ></ion-img>
      </div>
      <h2>{{"install.title" | translate }}</h2>
      <div class="allWidth flex-col-vertical-center ion-margin-top">
        <ion-img
          class="button_store hover ion-margin-top"
          (click)="openPlayStore()"
          src="assets/images/store/icon_GooglePlay.png"
        ></ion-img>
        <ion-img
          class="button_store hover ion-margin-top"
          (click)="openAppStore()"
          src="assets/images/store/icon_AppStore.png"
        ></ion-img>
      </div>
    </div>
  </div>
</ion-content>
