import { Component, OnInit, HostListener, Input } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ServicesService } from '@app/services/services.service';
import { LoginPage } from '../login/login.page';

@Component({
  selector: 'app-register',
  templateUrl: './register.page.html',
  styleUrls: ['./register.page.scss'],
})
export class RegisterPage implements OnInit {
  validations_form_email: FormGroup;
  randomImage: string = '';

  constructor(
    public services: ServicesService,
    private formBuilder: FormBuilder
  ) {
    this.randomImage = this.services.utilsService.randomImageCategories();

    this.validations_form_email = this.formBuilder.group({
      email: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(255),
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ])
      ),
    });
  }

  email: string = null;

  ngOnInit() {}

  loginSocial(social: string) {
    this.services.sessionService.loginSocial(social).then(() => {
      this.services.utilsService.presentToast('login.connected', 'checkmark');

      setTimeout(() => {
        this.dismiss();
      }, 200);
    });
  }

  register() {
    if (!this.validations_form_email.valid) {
      this.validations_form_email.markAllAsTouched();
      return;
    }
    this.email = this.validations_form_email.controls['email'].value;

    this.email = this.email.toLowerCase();
    this.email = this.email.replace(' ', '');
    this.services.utilsService.presentLoading();

    let data = JSON.stringify({
      unique_identifier: this.services.sessionService.unique_identifier,
      email: this.email,
      language: this.services.sessionService.language,
      timezone: this.services.utilsService.getTimezone(),
    });

    this.services.apiService
      .post('auth/register', data)
      .subscribe({
        next: (data: any) => {
          if (data.status_code == 200) {
            //this.services.utilsService.presentToast('register.successful');

            this.services.sessionService.setToken(data.access_token);

            this.services.utilsService.presentToast(
              'login.connected',
              'checkmark'
            );

            this.dismiss();
          } else if (data.status_code == 402) {
            this.services.utilsService.presentToast(
              'login.problemEmail',
              'alert-circle',
              'warning-toast'
            );
          } else {
            this.services.utilsService.presentToast(
              'register.emailExist',
              'alert-circle',
              'warning-toast'
            );
          }
        },
        error: (e) => {
          console.error(e);
        },
        complete: () => {},
      })
      .add(() => {
        this.services.utilsService.dismissLoading();
      });
  }

  @HostListener('document:keypress', ['$event'])
  enterKey(event) {
    if (event.key == 'Enter') {
      this.register();
    }
  }

  async login() {
    this.dismiss();
    setTimeout(async () => {
      this.services.modalService.openLogin(this.email);
    }, 200);
  }

  ionViewWillEnter() {
    const modalState = {
      modal: true,
      desc: 'fake state for our modal',
    };
    history.pushState(modalState, null!);
  }

  ionViewWillLeave() {
    if (window.history.state.modal) {
      window.history.back();
    }
  }

  @HostListener('window:popstate', ['$event'])
  async dismiss(role: string = 'dismiss') {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    const modal = await this.services.modalController.getTop();
    if (modal) {
      this.services.modalController.dismiss(null, role);
      return;
    }
  }
}
