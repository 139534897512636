import { Component, OnInit, HostListener, Input } from '@angular/core';
import { ServicesService } from '@app/services/services.service';

@Component({
  selector: 'app-install',
  templateUrl: './install.page.html',
  styleUrls: ['./install.page.scss'],
})
export class InstallPage implements OnInit {
  randomImage: string = '';
  showAndroid: boolean = false;
  showIOS: boolean = false;

  constructor(public services: ServicesService) {
    this.randomImage = this.services.utilsService.randomImagePsychology();
    if (
      this.services.utilsService.isAndroid &&
      !this.services.utilsService.isNativeAndroid
    ) {
      this.showAndroid = true;
    }
    if (
      this.services.utilsService.isIOS &&
      !this.services.utilsService.isNativeIOS
    ) {
      this.showIOS = true;
    }
  }

  ngOnInit() {}

  ionViewWillEnter() {
    const modalState = {
      modal: true,
      desc: 'fake state for our modal',
    };
    history.pushState(modalState, null!);
  }

  ionViewWillLeave() {
    if (window.history.state.modal) {
      window.history.back();
    }
  }

  openAppStore() {
    this.services.dataLayerService.logEvent(
      'prospect',
      'signup',
      'signup',
      'signup'
    );

    let url =
      'https://apps.apple.com/us/app/loveflame-boost-your-intimacy/id6670175124';

    if (this.services.sessionService.language == 'fr') {
      url =
        'https://apps.apple.com/fr/app/loveflame-boost-your-intimacy/id6670175124';
    }

    if (this.services.sessionService.language == 'de') {
      url =
        'https://apps.apple.com/de/app/loveflame-boost-your-intimacy/id6670175124';
    }

    this.services.browserService.openUrl(url);
  }

  openPlayStore() {
    this.services.dataLayerService.logEvent(
      'prospect',
      'signup',
      'signup',
      'signup'
    );

    let url =
      'https://play.google.com/store/apps/details?id=app.loveflame.web.twa&hl=' +
      this.services.sessionService.language;

    this.services.browserService.openUrl(url);
  }

  @HostListener('window:popstate', ['$event'])
  async dismiss(role: string = 'dismiss') {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    const modal = await this.services.modalController.getTop();
    if (modal) {
      this.services.modalController.dismiss(null, role);
      return;
    }
  }
}
