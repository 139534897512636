import { ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { ApiService } from './services/api.service';
import { DateService } from './services/date.service';
import { UtilsService } from './services/utils.service';
import { EventService } from './services/event.service';
import { StorageService } from './services/storage.service';
import { ServicesService } from './services/services.service';
import { TranslateService } from '@ngx-translate/core';
import { MenuService } from './services/menu.service';
import { SessionService } from './services/session.service';
import { DataService } from './services/data.service';
import { AiService } from './services/ai.service';
import { GiorgioService } from './services/giorgio.service';
import { BrowserService } from './services/browser.service';
import { ModalService } from './services/modal.service';
import { PushNotificationService } from './services/push-notification.service';
import { SwUpdate } from '@angular/service-worker';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { environment } from '@env/environment';
import { DataLayerService } from './services/data-layer.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  displayMenu: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public swUpdate: SwUpdate,
    private apiService: ApiService,
    private dateService: DateService,
    private utilsService: UtilsService,
    private storageService: StorageService,
    private eventService: EventService,
    public services: ServicesService,
    private translateService: TranslateService,
    private menuService: MenuService,
    private sessionService: SessionService,
    private dataService: DataService,
    private giorgioService: GiorgioService,
    private aiService: AiService,
    private browserService: BrowserService,
    private modalService: ModalService,
    private pushNotificationService: PushNotificationService,
    private dataLayerService: DataLayerService,
    private cdr: ChangeDetectorRef
  ) {
    // Désactivation suivi
    if (!environment.production) {
      window['disable_stat'] = true;
    }
    if (swUpdate.isEnabled) {
      swUpdate.versionUpdates.subscribe((evt) => {
        switch (evt.type) {
          case 'VERSION_DETECTED':
            console.log(`Downloading new app version: ${evt.version.hash}`);
            break;
          case 'VERSION_READY':
            console.log(`Current app version: ${evt.currentVersion.hash}`);
            console.log(
              `New app version ready for use: ${evt.latestVersion.hash}`
            );
            // Disable automatic update because too many update
            /* this.services.menuService.updateIfPossible = true;
            this.services.storageService.set('willUpdate', 1);*/
            break;
          case 'VERSION_INSTALLATION_FAILED':
            console.log(
              `Failed to install app version '${evt.version.hash}': ${evt.error}`
            );
            break;
        }
      });
    }
  }

  ngOnInit() {
    this.services.apiService = this.apiService;
    this.services.dateService = this.dateService;
    this.services.utilsService = this.utilsService;
    this.services.storageService = this.storageService;
    this.services.eventService = this.eventService;
    this.services.translateService = this.translateService;
    this.services.menuService = this.menuService;
    this.services.sessionService = this.sessionService;
    this.services.dataService = this.dataService;
    this.services.giorgioService = this.giorgioService;
    this.services.aiService = this.aiService;
    this.services.browserService = this.browserService;
    this.services.modalService = this.modalService;
    this.services.pushNotificationService = this.pushNotificationService;
    this.services.dataLayerService = this.dataLayerService;

    this.services.storageService.isReady().subscribe((data) => {
      if (this.services.storageService.get('willUpdate') == 1) {
        this.services.storageService.set('willUpdate', 0);
        setTimeout(() => {
          this.services.utilsService.presentToast('app_updated');
        }, 500);
      }
    });

    if (document.URL.includes('#internal')) {
      this.openInternal(document.URL);
    }

    if (this.services.menuService.getParams?.internal != null) {
      this.openInternal(this.services.menuService.getParams?.internal);
    }

    this.services.eventService.subscribe('detectChanges', (data) => {
      this.cdr.detectChanges();
    });

    this.services.eventService.subscribe('menuDisplayed', (menuDisplayed) => {
      this.displayMenu = menuDisplayed;
    });

    if (
      window &&
      environment.production &&
      !document.URL.includes('dev') &&
      !document.URL.includes('localhost')
    ) {
      window.console.log =
        window.console.warn =
        window.console.info =
          function () {};
    }
  }

  openInternal(target) {
    // Example = localhost:8100#internal=modal_openPremium
    target = target.substring(target.indexOf('#') + 1);

    target = target.replace('internal_', '');
    target = target.replace('internal=', '');
    if (target.includes('modal_')) {
      target = target.replace('modal_', '');
      target = target.split('_');
      if (target.length > 0) {
        let name = target[0];
        target.shift();
        setTimeout(() => {
          this.services.modalService[name](...target);
        }, 1000);
      }
    }

    if (target.includes('redirect_')) {
      target = target.replace('redirect_', '');
      target = target.split('_');
      if (target.length > 0) {
        let url = '/' + target.join('/');
        this.services.menuService.navigate(url);
      }
    }
  }
}
