<ion-content>
  <div class="background-image">
    <ion-img src="{{randomImage}}"></ion-img>
    <div class="gradient"></div>
    <div class="gradient2"></div>
  </div>

  <ion-fab vertical="top" horizontal="end" slot="fixed">
    <ion-fab-button color="white" size="small" (click)="dismiss()">
      <ion-icon name="close"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <div class="container">
    <div class="middle allWidth flex-col-vertical-center">
      <h1>{{'makingof.title' | translate}}</h1>
      <app-divider></app-divider>
      <h2>{{'makingof.mockup' | translate}}</h2>
      <ion-img class="img" src="assets/images/making-of/mission.png"></ion-img>
      <ion-img class="img" src="assets/images/making-of/suivi.png"></ion-img>
      <ion-img
        class="img"
        style="border: none !important"
        src="assets/images/making-of/giorgio_first.gif"
      ></ion-img>
      <h2>{{'makingof.version1' | translate}}</h2>
      <ion-img
        class="img"
        src="assets/images/making-of/mission-2.png"
      ></ion-img>
      <ion-img class="img" src="assets/images/making-of/suivi-2.png"></ion-img>
      <ion-img
        class="img"
        style="border: none !important"
        src="assets/images/characters/specific/encouragement.png"
      ></ion-img>

      <h2>{{'makingof.version2' | translate}}</h2>
      <ion-img
        class="img"
        src="assets/images/making-of/mission-3.png"
      ></ion-img>
      <ion-img class="img" src="assets/images/making-of/suivi-3.png"></ion-img>
    </div>
  </div>
</ion-content>
