<ion-content>
  <div class="background-image">
    <ion-img src="{{randomImage}}"></ion-img>
    <div class="gradient"></div>
    <div class="gradient2"></div>
  </div>

  <ion-fab vertical="top" horizontal="end" slot="fixed">
    <ion-fab-button color="white" size="small" (click)="dismiss()">
      <ion-icon name="close"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <div class="container">
    <div class="middle allWidth flex-col-vertical-center">
      <div class="allWidth first-explication flex-col-center">
        <ion-img
          class="img-giorgio"
          src="assets/images/characters/specific/encouragement.png"
        ></ion-img>
      </div>
      <h1>{{'notification.title' | translate}}</h1>
      <p class="ion-text-center">{{'notification.explication' | translate}}</p>

      <div
        class="allWidth ion-text-center flex-col-center ion-margin-bottom ion-padding-start ion-padding-end"
      >
        <ion-button
          shape="round"
          fill="outline"
          color="white"
          size="large"
          (click)="dismiss()"
        >
          <ion-icon name="close" size="large" slot="icon-only"></ion-icon>
        </ion-button>

        <ion-button
          color="primary-gradient"
          shape="round"
          size="large"
          class="ion-margin-start"
          (click)="activateNotification()"
        >
          <ion-icon name="notifications" slot="end"></ion-icon>
          {{'notification.activate'|translate}}
        </ion-button>
      </div>
    </div>
  </div>
</ion-content>
